const environments = {
    'local': {
        label:  'local',
        apiUrl: 'http://localhost:8080/v1/',
    },
    'testing': {
        label:  'testing',
        apiUrl: 'https://api-backend-dot-securitas-audit-development.appspot.com/v1/',
    },
    'staging': {
        label:  'staging',
        apiUrl: 'https://api-backend-dot-audit-tool-staging.appspot.com/v1/',
    },
    'production': {
        label:  'production',
        apiUrl: 'https://api-backend-dot-audit-tool-production.appspot.com/v1/',
    },
}

export const environment = environments[process.env.REACT_APP_GCP_ENVIRONMENT];

export const version = {
    versionNumber: '0.4',
    lastUpdate: '29. Aug 2019',
} 

export const dateFormat = 'D MMM YYYY';
export const logoUrl = '/securitas-logo.svg';

// Score limits
// This is a temporary solution. These limits should be set for each client.
export const limits = {
    passed: 80, // above 80%
    fair: 60, // between 60% and 80%
    failed: 40, // below 40%
    binary: 50 // for widgets with either or
};

// Set the flag for showing the redux logger
export const USE_LOGGING = process.env.NODE_ENV === "local" || false

// Set languages here
export const LANGUAGES = {en: "English", de: "German"}

// Mapbox settings
export const MAPBOX_KEY = "pk.eyJ1IjoibmF2ZWVucmVkZHlpbiIsImEiOiJjanRyYXhyYTQwbTBkM3lrMGd0MWRsa2xnIn0.zkcL7SijwsaVo96uvzcGHA";
export const MAPBOX_STYLE = "mapbox://styles/naveenreddyin/cjtsaqkgk16i71fo1o1w0y3lm";
export const MAPBOX_STYLE_LOCATIONS_FORM = "mapbox://styles/naveenreddyin/cjv202ycx081q1fo4g1l2mfy2";

// Some score related coonstants
export const PASS_SCORE_MAX = 100;
export const PASS_SCORE_MIN = 80;
export const FAIR_SCORE_MAX = 79.9;
export const FAIR_SCORE_MIN = 40;
export const FAIL_SCORE = 39.9;
export const FAIL_COLOR = '#CC1814';
export const PASS_COLOR = '#4BCC14';
export const FAIR_COLOR = '#FFCE0D';

// **** AD Login *****
const clientId = 'e0d8f242-ff16-4b48-bbe8-59e804815fc1';
export const adLoginRequest = {
    scopes: ['user.read']
};

export const adRenewRequest = {
    scopes: [clientId]
};

export const adConfig = {
    auth: {
        clientId: clientId,
        authority: 'https://login.microsoftonline.com/fb0af7d1-8a83-48bc-8d2d-cd92034c79d4/',
        redirectUri: window.location.origin,
        response_type:'id_token',
        promt: 'login', 
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
}
