import React from 'react';

export const DispatchersList = (props) => {
    let { dispatchers } = props;

    return (
        <>
                    <div className="Table-container Map-table">

            <table className="table admin-table is-fullwidth ">
                <thead>
                    <tr>
                            <th className="cell-left">
                            <abbr>
                                Email
                            </abbr>
                            </th>
                            <th className="cell-left">
                            <abbr>
                                Full Name
                            </abbr>
                            </th>
                            <th className="cell-left">
                            <abbr>
                                Location
                            </abbr>
                            </th>
                            <th className="cell-left">
                            <abbr>
                                Phone
                            </abbr>
                            </th>
                            <th>
                            </th>
                    </tr>
                </thead>
                <tbody>
                    {dispatchers.map((item, i) => (
                        <tr key={i}>
                            <td className="cell-left">
                                {item.email}
                            </td>
                            <td className="cell-left">
                                {item.firstName} {item.lastName}
                            </td>
                            <td className="cell-left">
                                {item.locationName}
                            </td>
                            <td className="cell-left">
                                {item.phone}
                            </td>
                            <td className="cell-center">
                                <button className="button delete-btn" onClick={_ => props.onDelete({ auditKey: item.auditKey })}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </>
    );
}