import React from 'react';

export const Field = ({ name, label, value, type, placeholder, onChange, onKeyPress, disabled = false }) => {
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <input
                    className="input"
                    name={name}
                    value={value}
                    type={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export const CheckBox = ({ label }) => {
    return (
        <div className="field">
            <div className="control">
                <label className="checkbox">
                    <input type="checkbox" />
                    {label}
                </label>
            </div>
        </div>
    );
};

export const Category = ({ categories = [], label, name, onChange }) => {
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <div className="select">
                    <select name={name} onChange={onChange}>
                        {
                            Object.keys(categories).map(function (key) {
                                return <option key={key} value={categories[key].key}>{categories[key].caption}</option>;
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    );
};