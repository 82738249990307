import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    fetchDispatchers: [],
    setData: ['data'],
    addDispatch: ['dispatchInfo'],
    setMessage: ['message'],
    deleteDispatch: ['dispatchInfo']
})

export const DispatchTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: { instances: [], auditors: [], locations: [] },
    dispatchInfo: null,
    message: null,
})

/* ------------- Reducers ------------- */

export const fetchDispatchers = (state: Object, object: Object) => {
    return state;
}

export const setData = (state: Object, { data }: Object) => {
    return state.merge({ data });
}

export const addDispatch = (state: Object, { dispatchInfo }: Object) => {
    return state;
}

export const deleteDispatch = (state: Object, { dispatchInfo }: Object) => {
    return state;
}

export const setMessage = (state: Object, { message }: Object) => {
    return state.merge({ message });
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_DATA]: setData,
    [Types.FETCH_DISPATCHERS]: fetchDispatchers,
    [Types.ADD_DISPATCH]: addDispatch,
    [Types.SET_MESSAGE]: setMessage,
    [Types.DELETE_DISPATCH]: deleteDispatch,
})
