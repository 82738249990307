import {
    createReducer,
    createActions
} from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */

const {
    Types,
    Creators
} = createActions({
    createLocation: ['locationName', 'latitude', 'longitude', 'placeName', 'city', 'country', 'postcode', 'client'],
    fetchLocations: [],
    setLocations: ['locations'],
    setClients: ['clients'],
    deleteLocation: ['location'],
    deleteError: ['deleteErrorMessage'],
    setMessage: ['locationMessage'],
    updateLocation: ['locationInfo'],
    updateSuccess: ['updateLocationSuccessMessage'],
    updateError: ['updateLocationErrorMessage']
})

export const LocationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    locationName: null,
    latitude: null,
    longitude: null,
    placeName: null,
    city: null,
    country: null,
    postcode: null,
    locations: [],
    location: null,
    client: null,
    clients: [],
    locationMessage: null,
    locationInfo: null,
    updateLocationSuccessMessage: null,
    updateLocationErrorMessage: null,
    deleteErrorMessage: null,
})

/* ------------- Reducers ------------- */

export const createLocation = (state: Object, object: Object) => {
    return state.merge({})
}

export const fetchLocations = (state: Object, object: Object) => {
    return state.merge({})
}

export const setLocations = (state: Object, {
    locations
}: Object) => {
    return state.merge({
        locations
    })
}

export const setClients = (state: Object, {
    clients
}: Object) => {
    return state.merge({
        clients
    })
}

export const deleteLocation = (state: Object, object: Object) => {
    return state.merge({})
}

export const setMessage = (state: Object, {
    locationMessage
}: Object) => {
    return state.merge({
        locationMessage
    })
}

export const updateLocation = (state: Object, object: Object) => {
    return state;
}

export const updateSuccess = (state: Object, {
    updateLocationSuccessMessage
}: Object) => {
    return state.merge({
        updateLocationErrorMessage: null,
        deleteErrorMessage: null,
        updateLocationSuccessMessage
    })
}

export const updateError = (state: Object, {
    updateLocationErrorMessage
}: Object) => {
    return state.merge({
        deleteErrorMessage: null,
        updateLocationSuccessMessage: null,
        updateLocationErrorMessage
    })
}

export const deleteError = (state: Object, {
    deleteErrorMessage
}: Object) => {
    return state.merge({
        updateLocationErrorMessage: null,
        updateLocationSuccessMessage: null,
        deleteErrorMessage,
    })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CREATE_LOCATION]: createLocation,
    [Types.FETCH_LOCATIONS]: fetchLocations,
    [Types.SET_LOCATIONS]: setLocations,
    [Types.DELETE_LOCATION]: deleteLocation,
    [Types.SET_CLIENTS]: setClients,
    [Types.SET_MESSAGE]: setMessage,
    [Types.UPDATE_LOCATION]: updateLocation,
    [Types.UPDATE_SUCCESS]: updateSuccess,
    [Types.UPDATE_ERROR]: updateError,
    [Types.DELETE_ERROR]: deleteError,
})