import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import { USE_LOGGING } from '../resources/config'

// Creates the store
export default (rootReducer, rootSaga) => {
    const middlewares = []
    // create logger 
    const logger = createLogger({
        predicate: (getState, { type }) => USE_LOGGING
      })
    middlewares.push(logger)
    // add saga middleware
    const sagaMiddleware = createSagaMiddleware()
    middlewares.push(sagaMiddleware)

    const store = compose(applyMiddleware(...middlewares))(createStore)(rootReducer);
    // kick off root saga
    sagaMiddleware.run(rootSaga)

    return store
}