import React, { useState } from 'react';
import ImageModal from './ImageModal';
import Api from '../services/Api';

const Thumbnail = ({ image, onError }) => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [highResImage, setHighResImage] = useState(null);
    const link = image.localRef ? image.localRef : '';

    const getImage = () => {
        const api = Api.create();
        setShowImageModal(true);
        api.getImage(image.auditkey, image.key).then(response => {
            if (response.status === 200) {
                setHighResImage(window.URL.createObjectURL(new Blob([response.data], {type: image.contentType})));
            } else {
                setShowImageModal(false);
            }
        });
    }

    const closeImageModal = () => {
        setShowImageModal(false);
    }

    return (
        <>
            {showImageModal && <ImageModal image={highResImage} onClick={closeImageModal} />}
            <div className="thumbnail-item">
                <img src={link} onError={onError} onClick={() => getImage()} alt="" />
            </div>
        </>
    );
}

export default Thumbnail;