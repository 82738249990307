import React, { Component } from 'react';
import DispatchAuditList from '../components/dispatch/DispatchAuditList';
import { Notification } from '../components/Elements';
import { catchServerErrors } from '../resources/utils';
import Dispatch from '../services/dispatch';
import { default as DispatchContainer } from '../containers/dispatch';


class Dispatcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'no',
            selectedTab: '',
            auditList: [],
            errorMsg: '',
            servererror: '',
        };
    }

    handleTabClick(e) {
        console.log('tab clicked');
        console.log(e.target.name);
    }

    resetErrors() {
        this.setState({
            errorMsg: '',
            servererror: ''
        });
    }

    getAudits() {
        this.resetErrors();
        Dispatch.getAuditsClient(this.props.client).then((res) => {
            this.setState({ auditList: res.data });
        }).catch((e) => {
            this.setState(catchServerErrors(e));
        });
    }

    render() {
        return (
            <>
            <div className="content_card_content">
                <DispatchAuditList audits={this.state.auditList} />
                {
                    this.state.errorMsg &&
                    <Notification variant={{ 'servererror': 'is-danger' }[this.state.errorMsg]}>
                        {
                            {
                                'passworderror': 'Password or username is incorrect',
                                'servererror': this.state.servererror,
                            }[this.state.errorMsg]
                        }
                    </Notification>
                }
                <DispatchContainer />
            </div>
            </>
        );

    }
}

export default Dispatcher;