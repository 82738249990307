import React from 'react';
import AuditType from '../../services/audittype';

let catMap = {};

const QuestionList = ({questions, categories, auditType, edit}) => {
    catMap = AuditType.catMap(categories);
    return (
        <div>
            <h2>{auditType.client} {auditType.title}</h2>
            <table>
                <tbody>
                    <tr><th>Key</th><th>Category</th><th>Question</th><th>Sort order</th></tr>
                    {
                        Object.keys(questions).map(function(key) {
                            return <ListItem key={key} edit={edit} question={questions[key]} />;
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

const ListItem = ({question, edit}) => {
    return (
        <tr>
            <td>{question.key}</td>
            <td>{catMap[question.category]}</td>
            <td>{question.heading}</td>
            <td>{question.sortOrder}</td>
            <td><button onClick={() => edit(question.key)} >edit</button></td>
        </tr>
    );
}

export default QuestionList;

