import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { pluralize } from '../../services/language';

const DoughnutChart = ({numbers, countries}) => {
    let labelsArray = countries;
    let numberOfFails = numbers;
    if (countries.length === 0) {
        return false;
    }

    const data = {
        labels: labelsArray,
        data: numberOfFails,

        datasets: [{
            
            data: numberOfFails,
            
           
            backgroundColor: [
                '#F9AC31', // green color
                '#0077B7', // grey color
                '#11C9B7', // grey color
                '#001233', // grey color


            ],
            borderWidth: 0,
        }],
        
        
    };

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor:'black',
                padding: 40,
                usePointStyle: true
            }
            
        },

        tooltips: {
            callbacks: {
               label: function (tooltipItem, data) {
                       var dataset = data.datasets[tooltipItem.datasetIndex];
                       var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                       var total = meta.total;
                       var currentValue = dataset.data[tooltipItem.index];
                       var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                       return currentValue + ' (' + percentage + '%)';
                   },
                   title: function (tooltipItem, data) {
                       return data.labels[tooltipItem[0].index];
                   }
            },
            backgroundColor: '#FFF',
            titleFontSize: 16,
            titleFontColor: 'black',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: false
        },
        cutoutPercentage: 85,
        
    }

    return (
        <div className="doughnut-item">
            <div className="doughnut-wrapper">
                <Doughnut
                    data={data}

                    options={options}
                />
                <h2 className="score-perc">{data['data'].reduce((total, amount) => total + amount)}</h2>
                <h4>{pluralize(numbers.length, 'Fail')}</h4>
            </div>

        </div>
    );
}

export default DoughnutChart;