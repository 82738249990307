import { put, call } from 'redux-saga/effects';

import AuditorActions from '../redux/AuditorRedux';
import API from '../services/Api';


export function* addAuditorSaga({ auditorInfo }) {
    let { firstName, lastName, email, phone } = auditorInfo;
    let successMessage = 'Auditor added successfully';
    let response = yield call(API.create().addAuditor, firstName, lastName, email, phone);
    if (response.status === 201) {
        yield put(AuditorActions.auditorSuccess(successMessage));
        yield put(AuditorActions.fetchAuditors());
    }
}

export function* fetchAuditorsSaga() {
    let response = yield call(API.create().fetchAuditors);
    if (response.status === 200) {
        yield put(AuditorActions.setAuditors(response.data));
    }
}

export function* deleteAuditorSaga({ auditorInfo }) {
    
    let response = yield call(API.create().deleteAuditor, auditorInfo.auditorKey);
    if (response.status === 200) {
        yield put(AuditorActions.auditorSuccess('Auditor deleted.'));
        yield put(AuditorActions.fetchAuditors());
    }
}

export function* updateAuditorSaga({ auditorInfo }) {
    let {auditorKey, firstName, lastName, email, phone} = auditorInfo;
    let response = yield call(API.create().updateAuditor, auditorKey, firstName, lastName, email, phone);
    if (response.status === 200) {
        yield put(AuditorActions.updateSuccess('Auditor updated.'));
        yield put(AuditorActions.fetchAuditors());
    }else{
        yield put(AuditorActions.updateError('Edit Error.'))
    }
}

