import L from 'leaflet';

import greenIcon from 'design/svg/map-pin-green.svg';
import redIcon from 'design/svg/map-pin-red.svg';
import amberIcon from 'design/svg/map-pin-amber.svg';


export const GreenIcon = L.icon({
    iconUrl: greenIcon,
    iconSize: [48, 48],
    iconAnchor: [24,36],
    popupAnchor: [25, -50]
});

export const RedIcon = L.icon({
    iconUrl: redIcon,
    iconSize: [48, 48],
    iconAnchor: [24, 36],
    popupAnchor: [25, -50]

});

export const AmberIcon = L.icon({
    iconUrl: amberIcon,
    iconSize: [48, 48],
    iconAnchor: [24,36],
    popupAnchor: [25, -50]

});