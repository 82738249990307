import { put, call } from 'redux-saga/effects';

import DashboardActions from '../redux/DashboardRedux';
import API from '../services/Api';


export function* fetchData({ instance }) {
    let response = yield call(API.create().fetchData, instance);
    if (response.status === 200) {
        let { data } = response
        yield put(DashboardActions.setData(data))
        yield put(DashboardActions.getSummary(instance));
    }
}

export function* setInstance({ instance }) {
    yield put(DashboardActions.getData(instance));
}

export function* getSummary({ instance }) {
    let response = yield call(API.create().getInstanceStatus, instance);
    if (response.status === 200) {
        let { data } = response;
        yield put(DashboardActions.setSummary(data));
    } else {
        yield put(DashboardActions.setSummary({}));
    }
}

export function* getAudit({ audit }) {
    let response = yield call(API.create().getAudit, audit);
    if (response.status === 200) {
        yield put(DashboardActions.setAuditData(response.data));
    } else {
        yield put(DashboardActions.setAuditData({}));
    }
}

export function* getInstances() {
    let response = yield call(API.create().getInstances);
    if (response.status === 200) {
        yield put(DashboardActions.setInstances(response.data));
        // Load the first instance that the user is allowed to access
        if (Array.isArray(response.data) || response.data.length) {
            yield put(DashboardActions.setInstance(response.data[0].instanceKey));
            yield put(DashboardActions.getData(response.data[0].instanceKey));
        }
    } else {
        yield put(DashboardActions.setInstances([]));
    }
}
