import React from 'react';

import { formatedDate } from '../../resources/utils';

const AuditDetail = ({ audit }) => {

  return (
    <div className="card primary-card">
      <div className="breadcrumbn"><p>Dispatch / <b>Audit Instance</b></p></div>

      <div className="audit-list-item">
        <div className=" is-two-thirds">
          <h3 className="audit-name">{audit.title} for {audit.client} </h3>
          <p className="audit-desc">Audit to check if the facilities in the premises are up to standard.</p></div>
        <div className="audit-selector">

          <button className="audit-select-btn" title="({audit.key})<">Assign</button>
        </div></div>
      <div className="columns card-meta-row">
        <div className="column is-two-thirds">
          <div className="tile is-ancestor">
            <div className="tile is-vertical is-parent is-4 ">
              <div className="tile">

                <div>
                  <p className=" meta-label">Created by</p>
                  <p className="card-data">{audit.createdBy} </p>
                </div>
              </div>

            </div>
            <div className="tile is-vertical is-parent is-4 ">

              <div className="tile">
                <div>
                  <p className=" meta-label">Created on</p>
                  <p className="card-data">{formatedDate(audit.created)}</p>
                </div>
              </div>



            </div>
            <div className="column is-parent">
              <div className="tile">

                <div>
                  <p className=" meta-label">Assignees</p>
                  <p className="card-data"><span className="int-link" href="#">{audit.auditor}</span></p>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AuditDetail;