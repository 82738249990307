import { combineReducers } from 'redux'
import configureStore from './CreateStore'

import rootSaga from '../sagas'

export default () => {
    /* ------------- Assemble The Reducers ------------- */
    const rootReducer = combineReducers({
        language: require('./LanguageRedux').reducer,
        dashboard: require('./DashboardRedux').reducer,
        login: require('./LoginRedux').reducer,
        location: require('./LocationRedux').reducer,
        auditor: require('./AuditorRedux').reducer,
        dispatch: require('./DispatchRedux').reducer
    })

    return configureStore(rootReducer, rootSaga)
}