import React from 'react'


export const CicularSVG = ({ color }) => {

    return (
        <>
            <svg className="score-circle" >
                <circle cx="10" cy="13" r="7" fill={color} />
            </svg>
        </>
    )
}