import axios from 'axios';

export default class Api {

    static getConfig() {
        return {headers: {'Token': sessionStorage.getItem('token')}}
    }

    static get(url) {
        return axios.get(url, this.getConfig());
    };

    static post(url, payload) {
        return axios.post(url, payload, this.getConfig());
    };

    static patch(url, payload) {
        return axios.patch(url, payload, this.getConfig());
    };

    static put(url, payload) {
        return axios.put(url, payload, this.getConfig());
    };

}