import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const appStorage = window.localStorage;

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  login: ['name', 'email', 'authMethod'],
  setToken: ['token'],
  setUserInfo: ['userInfo'],
})

export const LoginTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  name: null,
  email: null,
  authMethod: null,
  token: null,
  userInfo: appStorage.getItem('userInfo') || null,
})

/* ------------- Reducers ------------- */

export const login = (state: Object, object: Object) => {
  return state.merge({})
}

export const setToken = (state: Object, { token }: Object) => {
  sessionStorage.setItem('token', token);
  return state.merge({ token })
}

export const setUserInfo = (state: Object, { userInfo }: Object) => {
  appStorage.setItem('userinfo', JSON.stringify(userInfo));
  return state.merge({ userInfo })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.SET_TOKEN]: setToken,
  [Types.SET_USER_INFO]: setUserInfo,
})
