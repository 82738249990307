import React from 'react';

import LocationUpdateRow from './LocationUpdateRow';


const LocationTable = (props) => {


    return (
        <>
            <div className="Table-container Map-table">
                <table className="table is-fullwidth is-hoverable ">
                    <thead>
                        <tr>
                            <th className="cell-left"><abbr title='Location Name'>Location Name</abbr></th>
                            <th><abbr title='Latitude'>Latitude</abbr></th>
                            <th><abbr title="Longitude">Longitude</abbr></th>
                            <th><abbr title="Address">Address</abbr></th>
                            <th><abbr title="postcode">Postcode</abbr></th>
                            <th><abbr title="City">City</abbr></th>
                            <th><abbr title="Country">Country</abbr></th>
                            <th><abbr title="Client">Client</abbr></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.locations.map((location, i) => (
                            <React.Fragment key={i}>
                                <LocationUpdateRow 
                                    toggle={() => props.toggle()}
                                    showModal={props.showModal}
                                location={location} update={props.updateLocation} deleteLocation={props.deleteLocation} />
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default LocationTable;