import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';

import Control from 'react-leaflet-control';

import { CicularSVG } from './CicularSVG';
import {
    PASS_SCORE_MAX, PASS_SCORE_MIN, FAIR_SCORE_MAX,
    FAIR_SCORE_MIN, FAIL_SCORE, PASS_COLOR, FAIR_COLOR,
    FAIL_COLOR
} from '../../resources/config';

export const TopRightControl = ({ countryTextOrLocation, countryLocations, score }) => {
    let [color, setColor] = useState('#fff')

    useEffect(() => {
        if (score <= FAIL_SCORE) {
            setColor(FAIL_COLOR);
        }
        else if (score >= FAIR_SCORE_MIN && score < FAIR_SCORE_MAX) {
            setColor(FAIR_COLOR);
        }
        else if (score >= PASS_SCORE_MIN && score <= PASS_SCORE_MAX) {
            setColor(PASS_COLOR);
        }
    }, [score]);

    return (

        <Control position="topright" className="map-info-box">
            <p>
                <span className="location-name">
                    {countryTextOrLocation}
                </span>
            </p>
            <p className="location-number">
                {countryLocations} locations
            </p>
            <p>
                <CicularSVG color={color} />
                <span className="inline-score">{score}%</span>
            </p>
            <p className="secondary-text">
                Total score
            </p>
        </Control>

    )
}


TopRightControl.propTypes = {
    countryTextOrLocation: PropType.string.isRequired,
    countryLocations: PropType.number.isRequired,
    score: PropType.number.isRequired,
}