import React from 'react';
import { LanguageSwitcher } from './LanguageSwitcher';
import t from '../translations'
import {  logoUrl } from '../resources/config';


 const Profile = (props) => {
      
       return(
        <>
             <div className="profile-modal">
        <div className="profile-pic">
            <img alt="profile" src ={logoUrl}  />
            </div>
        <div className="profile-info">
        <h2>                            {props.firstName} {props.lastName}
</h2>
                                    <LanguageSwitcher
                                handleChange={props.handleLanguageChange}
                                language={props.language} />
        </div>
        <button className="button secondary-button sign-out" onClick={() => props.logOut()}> {t[props.language].logout}
</button>

    </div>
        </>


       );
       }
   


export default Profile;
