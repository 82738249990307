import React, { Component } from 'react';
import { connect } from 'react-redux';

import LeafletMap from '../containers/LeafletMap';
import ReportSummary from '../containers/ReportSummary';
import ReportCategories from '../containers/ReportCategories';
import FailedLocations from '../containers/FailedLocations';
import CategoryBreakdown from '../containers/CategoryBreakdown';
import Tabs from './Tab';
import AuditDetails from './AuditDetails';

import DashboardActions from '../redux/DashboardRedux';

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: ['Summary', 'Something'],
            change:false,
        };
    }

    changeTab(event) {
        this.props.setActiveState(event.currentTarget.id );
    }

    componentDidUpdate(prevProps) {
        if (this.props.audit !== prevProps.audit) {
            this.props.getAudit(this.props.audit);
        }
        if (this.props.auditData !== prevProps.auditData) {
            this.props.setActiveState('audit');
        }
    }

    render() {
        const { auditData } = this.props;
        return (
            <>
                <Tabs
                    tabs={this.state.tabs}
                    activeTab={this.props.activeTab}
                    audit={auditData}
                    onClick={(event) => this.changeTab(event)}
                    change= {this.props.change}
                />
                {this.props.activeTab === 'summary' &&
                    <div className="content_card_content summary">
                        <ReportSummary />
                        <ReportCategories />
                        <CategoryBreakdown />
                        <FailedLocations />
                        <div className="expanded-container is-active">
                            <hr />
                            <LeafletMap />
                        </div>
                    </div>
                }
                {this.props.activeTab === 'audit' &&
                    <div className="content_card_content">
                        <AuditDetails />
                    </div>
                }

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAudit: (audit) => dispatch(DashboardActions.getAudit(audit)),
        setActiveState: (activeTab) => dispatch(DashboardActions.setActiveState(activeTab)),
        setChange: (change) => dispatch(DashboardActions.setChange(change))

    }
}

const mapStateToProps = (state) => {
    return {
        audit: state.dashboard.audit,
        auditData: state.dashboard.auditData,
        activeTab: state.dashboard.activeTab,
        change: state.dashboard.change,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);