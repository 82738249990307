import React, { Component } from 'react';
import { connect } from 'react-redux'

import './design/styling.scss';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import Translate from './components/Translate';

const token = sessionStorage.getItem('token');

class App extends Component {
    render() {
        let { language } = this.props

        return (
            <Translate language={language}>
                {token ? (<Dashboard {...this.props}/>) : (<Login {...this.props}/>)}
            </Translate>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language.code
    }
}

export default connect(mapStateToProps, {})(App)