import React from 'react';
import { connect } from 'react-redux';
import DashboardActions from '../redux/DashboardRedux';
import { ReactComponent as Iconlayer } from 'design/svg/layers.svg';


const SubHeader = (props) => {

    const handleInstanceChange = event => {
        props.setInstance(event.target.value);
        props.setActiveState('summary');
        props.setChange(true);
        window.scrollTo(0, 0);

    }

    return (
        <div className="nav-header columns">
            <div className="field  column is-one-quarter  has-addons audittype-filter">
                <div className="control   is-expanded">
                    <div className="select is-fullwidth">
                        <span className="absolute-icon"><Iconlayer width={28} height={28} /></span>

                        <select name="instances" onChange={handleInstanceChange} defaultValue="1">
                            {props.instances.map((instance) =>
                                <option key={instance.instanceKey} value={instance.instanceKey}>
                                    {instance.title}
                                </option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="column is-offset-6 header-search">

            </div >
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setInstance: (instance) => dispatch(DashboardActions.setInstance(instance)),
        setAudit: (auditKey) => dispatch(DashboardActions.setAudit(auditKey)),
        setActiveState: (activeTab) => dispatch(DashboardActions.setActiveState(activeTab)),
        setChange: (change) => dispatch(DashboardActions.setChange(change))

    }
}

const mapStateToProps = (state) => {
    return {
        instance: state.dashboard.instance,
        summary: state.dashboard.summary,
        auditData: state.dashboard.auditData,
        audit: state.dashboard.audit,
        activeTab: state.dashboard.activeTab,
        change: state.dashboard.change,
        instances: state.dashboard.instances,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);