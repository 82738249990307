import React from 'react';
import PropType from 'prop-types';

import { LANGUAGES } from '../resources/config'

export const LanguageSwitcher = ({ handleChange, language }) => {
    const languageOptions = Object.keys(LANGUAGES).map((key, i) =>
        <option value={key} key={i}>{LANGUAGES[key]}</option>
    )
    return (
        <div className="select">
            <select value={language} onChange={handleChange}>
                {languageOptions}
            </select>
        </div>
    )
}

LanguageSwitcher.propTypes = {
    handleChange: PropType.func.isRequired,
    language: PropType.string.isRequired,
};