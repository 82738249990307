import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const appStorage = window.localStorage;

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setLanguage: ['code']
})

export const LanguageTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  code: appStorage.getItem('language') || "en"
})

/* ------------- Reducers ------------- */

export const setLanguage = (state: Object, { code }: Object) => {
    appStorage.setItem('language', code)
    return state.merge({ code })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LANGUAGE]: setLanguage,
})
