import apisauce from 'apisauce';

import { environment as env } from '../resources/config';

import LoginService from './LoginService';

const create = (baseURL = env.apiUrl) => {
const token = sessionStorage.getItem('token');

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: token ? { 'Token': token } : {},
    // 10 second timeout...
    timeout: 10000
  })

  // A example code snippet to force API Key on all requests if it is there
  api.addRequestTransform((request) => {
    // request.params['APPID'] = '0e44183e8d1018fc92eb3307d885379c'
  })

  api.addAsyncRequestTransform(request => async () => {
    if (LoginService.getAccount()) {
      LoginService.loginSilent().then(login => {
        sessionStorage.setItem('token', login.rawIdToken);
        api.setHeader('token', login.rawIdToken);
      });
    }
  })

  // POEditor apis
  // Totally bonkers to GET a list POST method is being used!
  const fetchTermsList = (api_token, project_id, language) => api.post('terms/list',
    {
      'api_token': api_token,
      'id': project_id,
      'language': language
    });

  const fetchData = (instance) => api.get(`audit/result/${instance}`);

  const addLocation = (locationName, latitude, longitude, placeName, city, country, postcode, client) => api.post('location', {
    'name': locationName,
    'address': placeName,
    'city': city,
    'postcode': postcode,
    'country': country,
    'position': {
      'latitude': latitude,
      'longitude': longitude
    },
    'client': client,
  });
  const fetchAllLocations = _ => api.get('location');

  const fetchLocationsByClient = (client) => api.get(`location/${client}`);

  const updateLocation = (locationKey, name, address, city, postcode, country, latitude, longitude) => api.put(`location/${locationKey}`, {
    'name': name,
    'address': address,
    'city': city,
    'postcode': postcode,
    'country': country,
    'position': {
      'latitude': latitude,
      'longitude': longitude
    }
  });

  const login = (email, name, authMethod) => api.post('open/login', {
    'name': name,
    'email': email,
    'authMethod': authMethod
  });

  const deleteLocation = (locationKey) => api.delete(`location/${locationKey}`);

  const getClients = () => api.get('clients');

  const addAuditor = (firstName, lastName, email, phone) => api.post('auditor', {
    'firstName': firstName,
    'lastName': lastName,
    'email': email,
    'phone': phone,
  })

  const fetchAuditors = _ => api.get('auditor');

  const deleteAuditor = (auditorKey) => api.delete(`auditor/${auditorKey}`);

  const getInstances = _ => api.get('instance');

  const getLocations = _ => api.get('location');

  const fetchDispachers = _ => api.get('dispatch');

  const addDispatch = (locationKey, auditorKey, instanceKey) => {
    return api.post('dispatch', {
      'locationKey': parseInt(locationKey),
      'auditorKey': parseInt(auditorKey),
      'instanceKey': parseInt(instanceKey),
    })
  }

  const deleteDispatch = (auditKey) => api.patch(`dispatch/${auditKey}`, {
    'deleted': true
  });

  const updateAuditor = (auditorKey, firstName, lastName, email, phone) => api.put(`auditor/${auditorKey}`, {
    'firstName': firstName.trim(),
    'lastName': lastName.trim(),
    'email': email.trim(),
    'phone': phone.trim(),
  });

  const getInstanceStatus = (instanceKey) => api.get(`instancestatus/${instanceKey}`);

  const getThumb = (filename) => api.get(`audit/thumb/${filename}`,{},
    {responseType:'blob', accept:'*/*'}
  );

  const getImage = (auditKey, imageKey) => api.get(`audit/image/${auditKey}/${imageKey}`,{},
    {responseType:'blob', accept:'*/*'}
  ); 
  
  const getAudit = (auditKey) => api.get(`audit/${auditKey}`)

  return {
    fetchTermsList,
    fetchData,
    login,
    addLocation,
    fetchLocationsByClient,
    deleteLocation,
    getClients,
    addAuditor,
    fetchAuditors,
    deleteAuditor,
    getInstances,
    getLocations,
    fetchDispachers,
    addDispatch,
    deleteDispatch,
    fetchAllLocations,
    updateAuditor,
    updateLocation,
    getInstanceStatus,
    getThumb,
    getImage,
    getAudit,
  }
}
// let's return back our create method as the default.
export default {
  create
}