import React, { Component } from 'react';
import { connect } from 'react-redux'

import Header from '../components/Header';
import SideBar from '../components/SideBar';
import SubHeader from '../components/SubHeader';
import Login from './Login';
import DrillDown from '../containers/DrillDown';
import Dispatcher from './Dispatcher';
import CreateAudit from '../containers/CreateAudit';
import Report from '../components/Report';
import AddAuditorForm from '../containers/auditor';
import User from '../services/user';
import LanguageActions from '../redux/LanguageRedux';
import DashboardActions from '../redux/DashboardRedux';
import LoginActions from '../redux/LoginRedux';
import LocationActions from '../redux/LocationRedux';
import { version } from '../resources/config';
import LocationMap from '../components/widgets/location';
import WithLoading from '../components/Loading.js';
import LoginService from '../services/LoginService';

const ReportWithLoading = WithLoading(Report);

const appStorage = window.localStorage;
const sideBarMin = 58.02; // px
const sideBarMax = 247; // px
let sidebarClass = ''; // px
let sidebarW = sideBarMax;

const isTablet = window.innerWidth <= 1024;
if (isTablet){
sidebarW = sideBarMin;
sidebarClass = 'is-minimalised';
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            lang: 'no',
            didMount: false,
            selectedTab: '',
            client: 'CityCon',
            section: 'reporting',
            auditType: 'mvk4cGMjFMeNM3QQAsaT',
            userFirstName: 'Adam',
            userLastName: 'Curtis',
            showProfile: false,
            userName: '',
            isLoggedOut: false,
            sideBarWidth: sidebarW,
            widthClass: sidebarClass,
            zoom: 8,
            latitude: null,
            longitude: null,
            burgerOpen: false,
            showModal: false,
            loading:false
        };
        
    }
    
  toggleProfile(e) {
      this.setState({
          showProfile: this.state.showProfile ? false : true
      });
  }

toggleBurger(e) {
    this.setState({
        burgerOpen: this.state.burgerOpen ? false : true
    });
}
  componentWillMount(){
      document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClickOutside(e){
      if (this.state.showProfile) {
               this.toggleProfile();
     }
  }

  handleClick = (e) => {
      if (this.state.showProfile) {

      if (this.node.contains(e.target)){
          return;
      }
    }

      this.handleClickOutside(e);
  }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.getInstances();
        if (this.state.userName.length === 0) {
            const userInfo = JSON.parse(appStorage.getItem('userinfo'));
            this.props.setUserInfo(userInfo);
            // We should see this if fresh
            this.setState({
                userFirstName: userInfo.firstName,
                userLastName: userInfo.lastName,
                userName: userInfo.userName,
            });
            
        }
            setTimeout(() => {
                this.setState({
                    loading: false
                })
            }, 3000);
    }

    componentDidUpdate(prevProps, prevState, nextProps) {
        let { data } = this.props;
        let { dataSets } = data;
        if (prevProps.data !== this.props.data) {
            if (dataSets && dataSets.length > 0) {
                let [latitude, longitude] = [dataSets[0].locations[0].position.latitude, dataSets[0].locations[0].position.longitude];
                this.setLatLong(latitude, longitude);
            }
        }
    }

    setLatLong = (latitude, longitude) => {
        this.setState({
            latitude,
            longitude
        })
    }

    handleTabClick(e) {
        console.log(e.target.name);
    }

    handleSectionChange(section) {
        this.setState({ section: section });
    }

    handleSidebar(e) {
        const newWidth = this.state.sideBarWidth === sideBarMin ? sideBarMax : sideBarMin;
        const newClass = this.state.sideBarWidth === sideBarMin ? '' : 'is-minimalised';

        this.setState({
            sideBarWidth: newWidth,
            widthClass: newClass,
        }
        );
    }

    logOut() {
        if (User.logOut()) {
            this.setState({ isLoggedOut: true });
            this.setState({ showProfile: false });
            if (LoginService.getAccount()) {
                LoginService.logOut();
            }
        }
    }

     toggel(key, element) {
         if (element === 'submitmodal') {
             this.setState({
                 showModal: this.state.showModal ? false : true
             });
         }
     }

    handleLanguageChange = (event) => {
        let { value } = event.target
        this.props.setLanguage(value)
    }

    handleOnCountryRowClick = (countryOrLocation, isCountry = false) => {
        if (isCountry) {
            let [latitude, longitude] = [countryOrLocation.locations[0].position.latitude, countryOrLocation.locations[0].position.longitude]
            this.setLatLong(latitude, longitude)
        } else {
            let [latitude, longitude] = [countryOrLocation.position.latitude, countryOrLocation.position.longitude]
            this.setLatLong(latitude, longitude)
        }
    }

    render() {
        if (this.state.isLoggedOut) {
            return <Login {...this.props} />
        }
        let { language } = this.props

        return (
            <div>
                <div  ref={node => this.node = node} className="site-header">
                    <Header
                        firstName={this.state.userFirstName}
                        lastName={this.state.userLastName}
                        handleLanguageChange={this.handleLanguageChange}
                        toggleProfile={(e) => this.toggleProfile(e)}
                        toggleBurger={(e) => this.toggleBurger(e)}
                        burgerOpen ={this.state.burgerOpen}
                        showProfile = {this.state.showProfile}
                        language={language}
                        logOut={() => this.logOut()}
 />                 
                        
                    <SubHeader language={language} />
                </div>
                <SideBar
                    activeSection={this.state.section}
                    changeSection={(section) => this.handleSectionChange(section)}
                    sideBarAction={(e) => this.handleSidebar(e)}
                    sideBarWidth={this.state.sideBarWidth}
                    widthClass={this.state.widthClass}
                    language={language}
                />


                <div id="main-container">
                    <section className="main-section">
                        {
                            {
                                'reporting':   <ReportWithLoading isLoading={this.state.loading} />,
                                'drilldown': <DrillDown />,
                                'dispatch': <Dispatcher client={this.state.client} />,
                                'locationform': <LocationMap />,
                                'auditorform': <AddAuditorForm />,
                                'edittype': <CreateAudit auditType={this.state.auditType} />
                            }[this.state.section]
                        }
                    </section>

                    <p className="no-print">Version {version.versionNumber} ({version.lastUpdate})</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.dashboard.data,
        instance: state.dashboard.instance,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLanguage: (code) => dispatch(LanguageActions.setLanguage(code)),
        setUserInfo: (userInfo) => dispatch(LoginActions.setUserInfo(userInfo)),
        fetchData: () => dispatch(DashboardActions.getData()),
        fetchLocations: () => dispatch(LocationActions.fetchLocations()),
        getInstances: () => dispatch(DashboardActions.getInstances()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)