import React from 'react';

const AuditSelector = ({title, description}) => {

    return (
        <div className="audit-list-item">
            <div className="audit-info">
                <h3 className="audit-name">{title}</h3>
                <p className="audit-desc">{description}</p>
            </div>
        <div className="audit-selector">
         <button className="audit-select-btn">Select</button>
        </div>
           
        </div>
    );
}

export default AuditSelector;