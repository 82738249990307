export const audits = {
    1: {
        title: 'Fire alarm audit',
        description: 'An audit to check if fire alarm facilities in the premises are up to standard',
    },
    2: {
        title: 'Coffee quality audit',
        description: 'An audit to checkif the coffee quality is up to standard',
    },
    3: {
        title: 'Health and safety audit',
        description: 'An audit to check if the premises are safe for work',
    },
    4: {
        title: 'Internet audit',
        description: 'An audit to check if the premises are up to standard',
    },
    5: {
        title: 'Customer service audit',
        description: 'An audit to check if the premises are up to standard',
    },
};