import React from 'react';


const Modal = ({ show, toggle, message, wrapperRef }) => {
  

    return (
        <>
   <div className={"modal " + (show ? 'is-active' : '')}>
            <div className="modal-background" onClick={() => toggle()} ></div>
            <div className="modal-content" ref={wrapperRef}>
                <div className="box">
                    <div className="warning-status"><span className="alert-icon warning "></span></div>
                    <div>
                       {message}
                    </div>
    <button onClick={() => toggle()} className="modal-close is-large" aria-label="close"></button>

                </div>
            </div>
        </div>
        </>
    );
}

export default Modal;