import React, { useState, useEffect } from 'react';

import { Field } from '../../FormElements';

const AuditorTableUpdateRow = ({ auditor, update, deleteAuditor }) => {

    let [firstName, setFirstName] = useState('');
    let [lastName, setLastName] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');

    useEffect(() => {
        setFirstName(auditor.firstName);
        setLastName(auditor.lastName);
        setEmail(auditor.email);
        setPhone(auditor.phone);
    }, [auditor]);

    const handleFirstNameChange = event => {
        setFirstName(event.target.value);
    }

    const handleLastNameChange = event => {
        setLastName(event.target.value);
    }

    const handleEmailChange = event => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = event => {
        setPhone(event.target.value);
    }

    return (
        <>
            <tr>
                <td className="cell-left">
                    <Field name="firstName" value={firstName}
                        onChange={handleFirstNameChange}
                        style={{ width: '10px' }} />
                </td>
                <td className="cell-left">
                    <Field name="lastName" value={lastName}
                        onChange={handleLastNameChange}
                        style={{ width: '10px' }} />
                </td>
                <td className="cell-left">
                    <Field name="email" value={email}
                        onChange={handleEmailChange}
                        style={{ width: '10px' }} />
                </td>
                <td className="cell-left">
                    <Field name="phone" value={phone}
                        onChange={handlePhoneChange}
                        style={{ width: '10px' }} />
                </td>
                <td className="cell-center">
                    <button
                        className="button update-btn"
                        onClick={_ => {
                            const auditorInfo = {
                                'auditorKey': auditor.auditorKey,
                                'firstName': firstName,
                                'lastName': lastName,
                                'email': email,
                                'phone': phone,
                            }
                            update(auditorInfo);
                        }}
                    >
                        Update
                    </button>
                </td>
                <td className="cell-center">
                    <button
                        className="button delete-btn"
                        onClick={_ => deleteAuditor(auditor)}
                    >
                        Delete
                    </button>
                </td>
            </tr>
        </>
    );
}

export default AuditorTableUpdateRow;