import React from 'react';
import { ReactComponent as Iconfolder } from 'design/svg/file.svg';
import { ReactComponent as Icondoc } from 'design/svg/document.svg';

const Tab = ({ activeTab, audit, onClick, change }) => {
   
    return (
        <div>
            <div className="tabs card_tabs">
                <ul>
                    <li id="summary" className={activeTab === 'summary' ? "is-active" : ""} onClick={(event) => onClick(event)} >
                       <span> <Iconfolder width={22} height={22} fill={'#fff'} />
                        Summary</span>
                    </li>
                    
                    {audit.location && !change &&
                        <li id="audit" className={activeTab === 'summary' ? "" : "is-active"} onClick={(event) => onClick(event)} >
                            <span> <Icondoc width={22} height={22} fill={'#fff'} />
                          {audit.location.name}</span> 
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
}

export default Tab;