import React, { useState, useEffect } from 'react';

import { Field } from '../../FormElements';

const MapForm = (props) => {

    let [locationName, setLocationName] = useState("");

    let [postcode, setPostcode] = useState("");

    let [error, setError] = useState(null);

    let [client, setClient] = useState(null);

    const handleLocationNameChange = event => {
        setLocationName(event.target.value);
    }

    const handlePostcodeChange = event => {
        setPostcode(event.target.value);
    }

    const handleClientChange = event => {
        setClient(event.target.value)
    }

    useEffect(() => {
        if (props.result && props.result.postcode)
            setPostcode(props.result.postcode);
        if(props.clients.length > 0)
            setClient(props.clients[0].key)
    }, [props.result, props.clients]);

    return (
        <>
            {props.result &&
                <>
                    <Field name="locationName" value={locationName} label="Location Name"
                        onChange={handleLocationNameChange}
                        style={{ width: '10px' }} />
                    <Field name="latitude" value={props.viewPort.latitude} label="Latitude"
                        style={{ width: '10px' }} disabled={true} />
                    <Field name="longitude" value={props.viewPort.longitude} label="Longitude"
                        style={{ width: '10px' }} disabled={true} />
                    <Field name="address" value={props.result.place_name} label="Address"
                        style={{ width: '10px' }} disabled={true} />
                    <Field name="city" value={props.result.city} label="City"
                        style={{ width: '10px' }} disabled={true} />
                    <Field name="country" value={props.result.country} label="Country"
                        style={{ width: '10px' }} disabled={true} />
                    <Field name="postcode" value={postcode} label="Postcode"
                        onChange={handlePostcodeChange}
                        style={{ width: '10px' }} />

                        {props.clients.length > 0 && 
                        <div className="field"><label className="label">Client</label>
                            <div className="control ">
                            <div className="form-select select"><select className="select" onChange={handleClientChange}>
                                {props.clients.map((item) => 
                                    <option key={item.key} value={item.key}>{item.name}</option>
                                )}
                        </select></div></div> </div>
                        }

                    {error &&
                        <p>{error}</p>
                    }

                    <button className="button add-btn"
                        onClick={_ => {
                            if (!locationName || !postcode || postcode === undefined) {
                                setError(<div className="notification is-warning">Please enter information in all fields.</div>);
                            } else {
                                props.handleShowAlert();
                                props.handleMapFormClick(locationName, props.viewPort.latitude,
                                    props.viewPort.longitude, props.result.place_name,
                                    props.result.city, props.result.country, props.result.countryCode, postcode, client);
                                setLocationName("");
                            }
                        }}
                    >
                        Add
                    </button>
                </>
            }
        </>
    );
}

export default MapForm;