import React from 'react';



const ImageModal = ({ image, onClick }) => {
    return (
        <div className={"modal is-active"} onClick={onClick}>
            <div className="modal-background"></div>
            <div className="modal-content">
                {image ? <img src={image}/> : < div className = "loading-container" > < div className = "loading" > Loading... </div></div> }
            </div>
        </div>  
    );
}



export default ImageModal;