import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    addAuditor: ['auditorInfo'],
    auditorSuccess: ['message'],
    fetchAuditors: [],
    setAuditors: ['auditorList'],
    deleteAuditor: ['auditorInfo'],
    updateAuditor: ['auditorInfo'],
    updateSuccess: ['updateMessage'],
    updateError: ['updateErrorMessage'],
})

export const AuditorTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    message: null,
    updateMessage: null,
    updateErrorMessage: null,
    auditorInfo: null,
    auditorList: [],
})

/* ------------- Reducers ------------- */

export const addAuditor = (state: Object, object: Object) => {
    return state
}

export const auditorSuccess = (state: Object, { message }: Object) => {
    return state.merge({ message });
}

export const fetchAuditors = (state: Object, object: Object) => {
    return state;
}

export const setAuditors = (state: Object, { auditorList }: Object) => {
    return state.merge({ auditorList });
}

export const deleteAuditor = (state: Object, object: Object) => {
    return state;
}

export const updateAuditor = (state: Object, object: Object) => {
    return state;
}

export const updateSuccess = (state: Object, { updateMessage }: Object) => {
    return state.merge({ updateMessage });
}

export const updateError = (state: Object, { updateErrorMessage }: Object) => {
    return state.merge({ updateErrorMessage });
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ADD_AUDITOR]: addAuditor,
    [Types.AUDITOR_SUCCESS]: auditorSuccess,
    [Types.FETCH_AUDITORS]: fetchAuditors,
    [Types.SET_AUDITORS]: setAuditors,
    [Types.DELETE_AUDITOR]: deleteAuditor,
    [Types.UPDATE_AUDITOR]: updateAuditor,
    [Types.UPDATE_SUCCESS]: updateSuccess,
    [Types.UPDATE_ERROR]: updateError
});
