import React, { PureComponent } from 'react';

import { connect } from 'react-redux'
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { MAPBOX_KEY, MAPBOX_STYLE_LOCATIONS_FORM } from '../../../resources/config';
import MapForm from './MapForm';
import LocationTable from './LocationTable';
import LocationActions from '../../../redux/LocationRedux';
import Modal from '../../Modal';


class LocationMap extends PureComponent {

    mapRef = React.createRef();

    state = {
        showingAlert: false,
        showModal: false,
        statusMessage: "",
        viewPort: {
            latitude: 59.903289,
            longitude: 10.742676,
            width: 100,
            height: 100,
            zoom: 8

        },
        result: null,
    };

    handleShowAlert = () => {
        this.setState({
            showingAlert: true
            
        });

        setTimeout(() => {
            this.setState({
                showingAlert: false
            });
        }, 2000);
    }

    toggel() {
            this.setState({
                showModal: this.state.showModal ? false : true
            });
        
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    /**
  * Set the wrapper ref
  */
    setWrapperRef(node) {

    }

    handleClickOutside(e) {

        if (this.state.showModal) {
            this.toggel();
            
        }
    }

    handleClick = (e) => {
        if (this.state.showModal) {

         /*   if (this.wrapperRef.contains(e.target)) {
                return;
            }*/
        }

        this.handleClickOutside(e);
    }

    componentDidMount() {
        this.props.fetchLocations();
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        document.removeEventListener('mousedown', this.handleClick, false);

    }

    resize = () => {
        let { latitude, longitude } = this.state.viewPort;
        this.handleViewportChange({
            width: window.innerWidth,
            height: window.innerHeight,
            latitude,
            longitude,
        });
    };

    handleViewportChange = (viewPort) => {
        // hard setting width and height
        viewPort.width = document.getElementById("main-container").clientWidth - 128;
        viewPort.height = 400;

        this.setState({
            viewPort: { ...viewPort }
        })
    }

    handleGeocoderViewportChange = (viewPort) => {
        const geocoderDefaultOverrides = { transitionDuration: 1000 }
        return this.handleViewportChange({
            ...viewPort,
            ...geocoderDefaultOverrides
        })
    }

    handleOnResult = event => {
        let result = {};
        result['place_name'] = event.result.place_name
        event.result.context.map((item) => {
            if (item.id.includes('postcode'))
                result['postcode'] = item.text;
            if (item.id.includes('place'))
                result['city'] = item.text;
            if (item.id.includes('country')) {
                result['country'] = item.text;
                result['countryCode'] = item.short_code;
            }
            return null;
        });

        this.setState({ result })
    }

    handleMapFormClick = (locationName, latitude, longitude, place_name, city, country, countryCode, postcode, client) => {
        country = countryCode.toUpperCase();
        window.scrollTo(0, this.mapRef.current.offsetTop);
        this.setState({ result: null });
        this.props.createLocation(locationName, latitude, longitude, place_name, city, country, postcode, client);
    }

    handleNavigationControlViewportChange = viewPort => {
        this.setState({ viewPort });
    }

    deleteLocation = (location) => {
        this.props.deleteLocation(location);
    }

    updateLocation = (location) => {
        this.props.updateLocation(location);
    }

    render() {

        let { viewPort, result } = this.state;
        const { locations, clients, deleteErrorMessage, updateLocationErrorMessage, updateLocationSuccessMessage } = this.props;
        let updateMessage = "";
        if (updateLocationSuccessMessage){
            updateMessage = updateLocationSuccessMessage;

        }    
        
        if (deleteErrorMessage) {
            updateMessage = deleteErrorMessage;

        }   
         if (updateLocationErrorMessage) {
            updateMessage = updateLocationErrorMessage;
        }
        
        return (
            <>
                <div className="content_card_content">
                  
                    {this.props.locationMessage &&
                        <div className={`notification is-success ${this.state.showingAlert ? "alert-shown" : "alert-hidden"}`}>{this.props.locationMessage}</div>
                    }

                    <Modal wrapperRef={this.wrapperRef} show={this.state.showModal} toggle={()=>this.toggel()} message={updateMessage} />

                    <ReactMapGL
                        {...viewPort}
                        ref={this.mapRef}
                        maxZoom={18}
                        minZoom={4}
                        mapboxApiAccessToken={MAPBOX_KEY}
                        mapStyle={MAPBOX_STYLE_LOCATIONS_FORM}
                        onViewportChange={this.handleViewportChange}
                    >
                        <div style={{ position: 'absolute', right: 0 }}>
                            <NavigationControl onViewportChange={this.handleNavigationControlViewportChange} />
                        </div>
                        <Geocoder
                            mapRef={this.mapRef}
                            onResult={this.handleOnResult}
                            onViewportChange={this.handleGeocoderViewportChange}
                            mapboxApiAccessToken={MAPBOX_KEY}
                            position="top-left"
                        />
                    </ReactMapGL>
                    <div className="form-container">
                        <MapForm viewPort={viewPort} result={result} handleShowAlert={this.handleShowAlert} handleMapFormClick={this.handleMapFormClick} clients={clients} />
                    </div>
                    {locations && locations.length > 0 &&
                        <LocationTable 
                        
                        toggle={() => this.toggel()}
                        ModalContent={(event) => this.ModalContent(event)}
                        showModal={this.state.showModal}
                        
                        locations={locations} deleteLocation={this.deleteLocation} updateLocation={this.updateLocation} />
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.location.locations,
        clients: state.location.clients,
        locationMessage: state.location.locationMessage,
        deleteErrorMessage: state.location.deleteErrorMessage,
        updateLocationErrorMessage: state.location.updateLocationErrorMessage,
        updateLocationSuccessMessage: state.location.updateLocationSuccessMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createLocation: (locationName, latitude, longitude, placeName, city, country, postcode, client) => {
            dispatch(LocationActions.createLocation(locationName, latitude, longitude, placeName, city, country, postcode, client))
        },
        fetchLocations: () => dispatch(LocationActions.fetchLocations()),
        deleteLocation: location => dispatch(LocationActions.deleteLocation(location)),
        updateLocation: (locationInfo) => dispatch(LocationActions.updateLocation(locationInfo)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMap)
