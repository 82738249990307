const endPoints = {
    token: 'token',
    login: 'open/login',
    getCategories: 'category/client/{client}',
    addQuestion: 'audit/type/addquestion',
    updateQuestion: 'audittype/question',
    getAuditTypeQuestions: 'audittype/question/{key}',
    getAuditTypeQuestion: 'audittype/question/{audittypekey}/{key}',
    getAuditType: 'audittype/{key}',
    getAuditsClient: 'audit/c/{client}',
}

export default endPoints;