import React, { Component } from 'react';

import {audits} from '../resources/audits';
import AuditList from '../components/AuditList';

class DrillDown extends Component {
	constructor(props) {
		super(props);
		this.state = {
            lang: 'no',
            audits: audits
		};
    }

    handleTabClick(e) {
        console.log('tab clicked');
        console.log(e.target.name);
    }
   
    render() {
        return (
            <AuditList audits={this.state.audits} />
        )
        
    }
}

export default DrillDown;