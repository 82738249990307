import React, { useState } from 'react';
import PropType from 'prop-types';

import { Map, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-control';

import MapBoxLayer from './MapboxLayer';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MapDataTable from './MapDataTable';
import { TopRightControl } from './TopRightControl';
import { CicularSVG } from './CicularSVG';
import { GreenIcon, RedIcon, AmberIcon } from './Icons';
import { MAPBOX_KEY, MAPBOX_STYLE } from '../../resources/config'


export const LeafletMap = React.forwardRef(({ latitude, longitude, zoom, getClusterStyle, data, onCountryRowClick }, ref) => {
    let position = [latitude, longitude];
    let [countryTextOrLocation, setcountryTextOrLocation] = useState(data.dataSets[0].countryName);
    let [countryLocations, setCountryLocations] = useState(data.dataSets[0].locations.length);
    let [score, setScore] = useState(data.dataSets[0].data.score);


    let setcountryTextOrLocationHelper = (name) => setcountryTextOrLocation(name);

    const handleClickOrHoverData = (country) => {
        setcountryTextOrLocationHelper(country.countryName)
        setCountryLocations(country.locations.length)
        setScore(country.data.score)
    }


    const handleCountryRowClick = (country, isCountry) => {
        onCountryRowClick(country, isCountry);
        if (isCountry)
            handleClickOrHoverData(country);
    }

    return (

        <div className="map-container" ref={ref}>
            <Map center={position} zoom={zoom} maxZoom={18} minZoom={3}>
                <MapBoxLayer
                    accessToken={MAPBOX_KEY}
                    style={MAPBOX_STYLE}
                />
                {data.dataSets.map((country, i) =>
                    <MarkerClusterGroup
                        iconCreateFunction={(e) => getClusterStyle(e, country)}
                        key={i}
                        onMouseOver={() => handleClickOrHoverData(country)}
                    >
                        {country.locations.map((location, i) => {

                            return (
                                <Marker
                                    position={[location.position.latitude, location.position.longitude]}
                                    key={i}
                                    riseOnHover={true}
                                    icon={location.data.score >= 80 ? GreenIcon :
                                        location.data.score >= 40 && location.data.score <= 79 ?
                                            AmberIcon : RedIcon}
                                >
                                    <Popup>
                                        <div className="info-container">
                                            <p className="location-name">{location.name}</p>
                                            <p className="location-extrainfo"><span></span> </p>

                                            <p className="location-address">{location.address}</p>
                                            <span className="location-country">{country.countryName}</span>
                                        </div>
                                        <div className="score-container"><p className="sco">
                                            <CicularSVG color={location.data.score >= 80 ? "#4BCC14" :
                                                location.data.score >= 40 && location.data.score <= 79 ?
                                                    "#FFCE0D" : "#CC1814"}
                                            />
                                            <span className="inline-score">{location.data.score}%</span> </p>
                                            <p className="secondary-text">Total score</p></div>
                                    </Popup>
                                </Marker>
                            )
                        })}
                    </MarkerClusterGroup>
                )}

                <TopRightControl
                    countryTextOrLocation={countryTextOrLocation}
                    countryLocations={countryLocations}
                    score={score}
                />

                <Control position="bottomright" className="map-legend">
                    <p><CicularSVG color="#4BCC14" /><span className="score-cat">Pass</span> <span className="score-range">80 - 100%</span></p>
                    <p><CicularSVG color="#FFCE0D" /><span className="score-cat">Fair</span> <span className="score-range">40 - 79%</span></p>
                    <p><CicularSVG color="#CC1814" /><span className="score-cat">Fail</span> <span className="score-range">0 - 39%</span></p>
                </Control>

            </Map>
            <MapDataTable data={data} onCountryRowClick={handleCountryRowClick} />
        </div>
    );
});


LeafletMap.propTypes = {
    latitude: PropType.number.isRequired,
    longitude: PropType.number.isRequired,
    zoom: PropType.number.isRequired,
    getClusterStyle: PropType.func.isRequired,
    data: PropType.object.isRequired,
    onCountryRowClick: PropType.func.isRequired,
};

export default LeafletMap;