import Api from './callapi';
import endPoints from '../resources/endpoints';

import {environment as env} from '../resources/config';

const appStorage = window.localStorage;

export default class User {

    static login(username, password) {
        const payload = {username: username, password: password};
        return Api.post(env.apiUrl + endPoints.login, payload);
    }

    static logOut() {
        sessionStorage.removeItem('token');
        return true;
    }

    static resetPassword(username, password, newPassword) {
        const payload = {username: username, password: password, newPassword: newPassword};
        return Api.put(env.apiUrl + endPoints.login, payload);
    }
}