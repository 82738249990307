import React, { useState, useEffect } from 'react';

import { Field } from '../../FormElements';
import { AuditorList } from './AuditorList';

const AddAuditorForm = (props) => {

    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");
    let [error, setError] = useState(null);

    const handleFirstNameChange = event => {
        setFirstName(event.target.value);
    }

    const handleLastNameChange = event => {
        setLastName(event.target.value);
    }

    const handleEmailChange = event => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = event => {
        setPhone(event.target.value);
    }

    useEffect(() => {
        if (props.error)
            setError(props.error);
    }, [props.error]);




    return (
        <>
            <div className="content_card_content">
            {error &&
               <> <p >{error}</p>
</>
            }
            {props.updateErrorMessage &&  
                    <>    <div className="notification is-warning">{props.updateErrorMessage}</div>

              <p >{error}</p>
                </>
            }
            {props.message &&
               <div className="notification is-success">{props.message}</div>
            }
            {props.updateMessage &&
            <div className="notification is-warning">{props.updateMessage}</div>

            }
            <Field name="firstName" value={firstName} label="First Name"
                onChange={handleFirstNameChange}
                style={{ width: '10px' }} />
            <Field name="lastName" value={lastName} label="Last Name"
                onChange={handleLastNameChange}
                style={{ width: '10px' }} />
            <Field name="email" value={email} label="Email"
                onChange={handleEmailChange}
                style={{ width: '10px' }} />
            <Field name="phone" value={phone} label="Phone"
                onChange={handlePhoneChange}
                style={{ width: '10px' }} />
            <button className="button add-btn"
                onClick={_ => {
                    if (!firstName || !lastName || !email) {
                        setError(<div className="notification is-warning">Please enter information in all fields.</div>);
                        return;
                    }
                    setError(null);
                    const auditorInfo = {
                        'firstName': firstName,
                        'lastName': lastName,
                        'email': email,
                        'phone': phone,
                    }
                    props.addAuditor(auditorInfo);
                }}
            >
                Add
            </button>
            <AuditorList {...props} />
            </div>
        </>
    );
}

export default AddAuditorForm;