import Api from './callapi';
import endPoints from '../resources/endpoints';
import {environment as env} from '../resources/config';


export default class Dispatch {

    static getAuditsClient(client) {
        const endPoint = endPoints.getAuditsClient.replace('{client}', client);
        return Api.get(env.apiUrl + endPoint);
    }

}