import React, { Component } from 'react';
import AuditType from '../services/audittype';

import QuestionTypeForm from './QuestionTypeForm';
import QuestionList from '../components/createaudit/QuestionList';


class CreateAudit extends Component {
	constructor(props) {
		super(props);
		this.state = {
            categories: [],
            auditTypes: null,
            auditId: null,
            auditTypeInfo: [],
            questions: [],
            editQuestion: '',
		};
    }

    componentDidMount() {
        if (this.state.categories.length === 0) {
            AuditType.getCategories('citycon').then((res) => {
                this.setState({
                    categories: res.data
                });
            });
        }
        if (this.state.auditTypeInfo.length === 0) {
            AuditType.getAuditType(this.props.auditType).then((res) => {
                this.setState({
                    auditTypeInfo: res.data
                });
                console.log('Audit type', res.data);
            });
        }
    };

    getAuditTypeQuestions() {
        AuditType.getQuestions(this.props.auditType).then((res) => {
            const data = res.data;
            data.sort(function (a, b) {
                return parseInt(a.sortOrder) - parseInt(b.sortOrder);
            });
            this.setState({
                questions: res.data
            });
        });
    };

    editQuestion(questionKey) {
        this.setState({
            editQuestion: questionKey
        });
    };

    render() {
        return (
            <div >
                <h1>Create Audit</h1>
                <button onClick={() => this.getAuditTypeQuestions()}>Get questions</button>
                <QuestionTypeForm categories={this.state.categories} auditType={this.props.auditType} editQuestion={this.state.editQuestion}/>
                <QuestionList questions={this.state.questions} auditType={this.props.auditType} edit={(key) => this.editQuestion(key)} categories={this.state.categories} />
            </div>
        );
    };
}

export default CreateAudit;