import React, { Component } from 'react';

import AuditType from '../services/audittype';
import { Field, Category } from '../components/FormElements';

class QuestionTypeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heading: '',
            hint: '',
            category: '',
            returnKey: '',
            sortOrder: 0,
            parent: '',
            condition: 0,
            currentQuestion: '',
            questionData: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.editQuestion !== prevProps.editQuestion) {
            AuditType.getQuestion(this.props.auditType, this.props.editQuestion).then((res) => {
                console.log(res);
                this.setState({
                    category: res.data.category,
                    heading: res.data.heading,
                    hint: res.data.hint,
                    parent: res.data.parent,
                    condition: res.data.condition,
                    sortOrder: res.data.sortOrder,
                    currentQuestion: res.data.key,
                });
            });
        }
    }

    handleInput({ target: { name, value } }) {
        this.setState({ [name]: value });
    };

    submit() {
        if (this.state.currentQuestion.length === 0) {
            this.createQuestion();
        } else {
            this.updateQuestion();
        }
    };

    updateQuestion() {
        const payload = {
            key: this.state.currentQuestion,
            heading: this.state.heading,
            hint: this.state.hint,
            category: this.state.category,
            auditType: this.props.auditType,
            sortOrder: parseInt(this.state.sortOrder),
            parent: this.state.parent,
            condition: parseInt(this.state.condition),
        };
        console.log('Update question', payload);
        AuditType.updateAuditQuestion(payload).then((res) => {
            console.log(res);
        });
    };

    createQuestion() {
        const payload = {
            heading: this.state.heading,
            hint: this.state.hint,
            category: this.state.category,
            auditType: this.props.auditType,
            sortOrder: parseInt(this.state.sortOrder),
        };
        if (this.state.parent.length !== 0) {
            payload.parent = this.state.parent;
            payload.condition = parseInt(this.state.condition);
        }
        console.log('Create question', payload);
        AuditType.addAuditQuestion(payload).then((res) => {
            console.log(res);
            this.setState({
                returnKey: res.data.key,
            });
        });
    };

    resetForm() {
        this.setState({
            heading: '',
            hint: '',
            parent: '',
            returnKey: '',
            sortOrder: 0,
            createQuestion: '',
        });
    }

    render() {
        const { categories } = this.props;
        return (
            <div>
                <Category label="Category" value={this.state.category} onChange={(event) => this.handleInput(event)} name="category" categories={categories} />
                <Field name="heading" value={this.state.heading} label="Question" onChange={(event) => this.handleInput(event)} />
                <Field name="hint" value={this.state.hint} label="Hint" onChange={(event) => this.handleInput(event)} />
                <Field name="parent" value={this.state.parent} label="Parent" onChange={(event) => this.handleInput(event)} />
                <Field name="condition" value={this.state.condition} label="Condition" onChange={(event) => this.handleInput(event)} />
                <Field name="sortOrder" value={this.state.sortOrder} label="Sort Order" onChange={(event) => this.handleInput(event)} />

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" onClick={() => this.submit()}>Submit</button>
                    </div>
                    <div className="control">
                        <button className="button is-text" onClick={() => this.resetForm()}>Reset form</button>
                    </div>
                </div>
                <p>{this.state.returnKey}</p>
            </div>
        );
    };
}

export default QuestionTypeForm;