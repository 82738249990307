import { takeEvery } from 'redux-saga/effects';

import { DashboardTypes } from '../redux/DashboardRedux';
import { LoginTypes } from '../redux/LoginRedux';
import { LocationTypes } from '../redux/LocationRedux';
import { AuditorTypes } from '../redux/AuditorRedux';
import { DispatchTypes } from '../redux/DispatchRedux';

import { fetchData, setInstance, getSummary, getAudit, getInstances } from './DashboardSaga';
import { login } from './LoginSaga';
import { createLocationSaga, fetchLocationsSaga, deleteLocationSaga, updateLocationSaga } from './LocationSaga';
import { addAuditorSaga, fetchAuditorsSaga, deleteAuditorSaga, updateAuditorSaga } from './AuditorSaga';
import { fetchDispatchersSaga, addDispatchSaga, deleteDispatchSaga } from './DispatchSaga';

export default function* root() {
    yield* [
        takeEvery(DashboardTypes.GET_DATA, fetchData),
        takeEvery(DashboardTypes.SET_INSTANCE, setInstance),
        takeEvery(DashboardTypes.GET_INSTANCES, getInstances),
        takeEvery(DashboardTypes.GET_SUMMARY, getSummary),
        takeEvery(DashboardTypes.GET_AUDIT, getAudit),
        takeEvery(LoginTypes.LOGIN, login),
        takeEvery(LocationTypes.CREATE_LOCATION, createLocationSaga),
        takeEvery(LocationTypes.FETCH_LOCATIONS, fetchLocationsSaga),
        takeEvery(LocationTypes.DELETE_LOCATION, deleteLocationSaga),
        takeEvery(AuditorTypes.ADD_AUDITOR, addAuditorSaga),
        takeEvery(AuditorTypes.FETCH_AUDITORS, fetchAuditorsSaga),
        takeEvery(AuditorTypes.DELETE_AUDITOR, deleteAuditorSaga),
        takeEvery(DispatchTypes.FETCH_DISPATCHERS, fetchDispatchersSaga),
        takeEvery(DispatchTypes.ADD_DISPATCH, addDispatchSaga),
        takeEvery(DispatchTypes.DELETE_DISPATCH, deleteDispatchSaga),
        takeEvery(AuditorTypes.UPDATE_AUDITOR, updateAuditorSaga),
        takeEvery(LocationTypes.UPDATE_LOCATION, updateLocationSaga),
    ]
}