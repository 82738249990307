import * as Msal from 'msal';
import { adConfig, adLoginRequest, adRenewRequest } from '../resources/config';

const msalApp = new Msal.UserAgentApplication(adConfig);

export default class LoginService {

    static loginSilent() {
        return msalApp.acquireTokenSilent(adRenewRequest, null).then(login => {
            return login.idToken;
        }).catch( error => {
            return error;
        });
    }

    static login() {
        return msalApp.loginPopup(adLoginRequest).then(login => {
            return login.idToken;
        }).catch( error => {
            console.log(error);
            return error;
        });
    }

    static getAccount() {
        return msalApp.getAccount();
    }

    static logOut() {
        sessionStorage.clear();
        return msalApp.logout();
    }
}