import React from 'react';
import { connect } from 'react-redux';
import { groupBy, filter } from 'lodash';
import {FAIL_SCORE} from '../resources/config'
import DoughnutChart from '../components/widgets/DoughnutChart';
import DashboardActions from '../redux/DashboardRedux';
import { pluralize } from '../services/language';

const FailedLocations = (props) => {
    const { locations, countries } = props.summary;
    if (!locations) {
        return false;
    }

    const auditChange = (auditKey) => {
        props.setAudit(auditKey);
        props.setChange(false);
        window.scrollTo(0, 0);
    }
    const failedyMap = [];

    //Function to get unique values of array
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

     //Get country names of the failed locations
    locations.forEach(c => {
        if (c.score < FAIL_SCORE) {
            failedyMap.push(c.countryName);
        }
    });

    var failedCountries = failedyMap.filter(onlyUnique);

    //Get only locations that have failed
    const preGrouped = filter(locations, function (o) {
        return o.score < FAIL_SCORE;
    });

    const grouped = groupBy(preGrouped, location => {
        return location.country;
    });

    const failNumbers = Object.keys(grouped).map(key => {
        return grouped[key].length;
    });

    const totalFails = Object.keys(preGrouped).length;
    
    const countryMap = {}; 
    countries.forEach(c => {
        countryMap[c.countryCode] = c.countryName;
    });

    const panel = Object.keys(grouped).map(key => {
        grouped[key].sort((a, b) => a.score - b.score);
        return (
            <div className="location-row " key={key}>
               <h4 className="location-parent">{countryMap[key]} ({grouped[key].length})</h4>
                {grouped[key].map(location => {
                    if (location.score < FAIL_SCORE){
                    return    <div className="location-item" key={location.auditKey}> 
                    <span className="location-name int-link card-data"  onClick={() => auditChange(location.auditKey)}>{location.siteName}</span> 
                    <span className="location-city">{location.city} ({location.score} %)</span></div>
                     }
                     return false;
                })}
            </div>
        );
    });

    if (failedCountries.length > 0) {
        return (
            <div className="columns fail-container">
                <div className="column is-full">
                    <article className="tile is-child box widget is-doughnut">
                        <h4 className="widget_title">Failed {pluralize(totalFails, 'location')} ({totalFails})</h4>
                        <DoughnutChart  numbers={failNumbers} countries={failedCountries}  />
                        <div className="location-list">
                            {panel}
                        </div>
                    </article>
                </div>
            </div>
        );
    } else {
        return false;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAudit: (auditKey) => dispatch(DashboardActions.setAudit(auditKey)),
        setActiveState: (activeTab) => dispatch(DashboardActions.setActiveState(activeTab)),
        setChange: (change) => dispatch(DashboardActions.setChange(change))
    }
}

const mapStateToProps = (state) => {
    return {
        summary: state.dashboard.summary,
        activeTab: state.dashboard.activeTab,
        change: state.dashboard.change,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FailedLocations);