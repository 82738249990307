import React, { Component } from 'react';
import { Provider } from 'react-redux'
import createStore from './redux'

import App from './App';

const store = createStore()

class Entry extends Component {
    render() {
      return (
        <Provider store={store}>
            <App/>
        </Provider>
      );
    }
  }
  
  export default Entry;