import Api from './callapi';
import endPoints from '../resources/endpoints';

import {environment as env} from '../resources/config';

export default class AuditTypes {

    static getAuditType(key) {
        const endPoint = endPoints.getAuditType.replace('{key}', key);
        return Api.get(env.apiUrl + endPoint);
    }

    static getCategories(client) {
        const endPoint = endPoints.getCategories.replace('{client}', client);
        return Api.get(env.apiUrl + endPoint);
    }

    static addAuditQuestion(payload) {
        return Api.post(env.apiUrl + endPoints.addQuestion, payload);
    }

    static updateAuditQuestion(payload) {
        return Api.patch(env.apiUrl + endPoints.updateQuestion, payload);
    }

    static getQuestions(auditTypeKey) {
        const endPoint = endPoints.getAuditTypeQuestions.replace('{key}', auditTypeKey);
        return Api.get(env.apiUrl + endPoint);
    }

    static getQuestion(auditTypeKey, questionKey) {
        const endPoint = endPoints.getAuditTypeQuestion.replace('{audittypekey}', auditTypeKey).replace('{key}',questionKey);
        return Api.get(env.apiUrl + endPoint);
    }

    static catMap(catList) {
        const cats = {};
        catList.forEach(element => {
            cats[element.key] = element.caption;
        });
        return cats;
    }
}