import { put, call } from 'redux-saga/effects';

import API from '../services/Api';
import DispatchActions from '../redux/DispatchRedux';

export function* fetchDispatchersSaga() {
    let data = {}
    let response = yield call(API.create().getInstances);
    if (response.status === 200) {
        data.instances = response.data;
        response = yield call(API.create().getLocations);
        data.locations = response.data;
        response = yield call(API.create().fetchAuditors);
        data.auditors = response.data;
    }

    // call fetchDispachers irrespective of others failing in responses
    response = yield call(API.create().fetchDispachers);
    data.dispatchers = response.data;
    yield put(DispatchActions.setData(data));
}

export function* addDispatchSaga({ dispatchInfo }) {
    let response = yield call(API.create().addDispatch, dispatchInfo.location, dispatchInfo.auditor, dispatchInfo.instanceType);
    if (response.status === 201) {
        yield put(DispatchActions.setMessage(`Dispatched Successfully.`))
        yield put(DispatchActions.fetchDispatchers());
    }
}

export function* deleteDispatchSaga({ dispatchInfo }) {
    let response = yield call(API.create().deleteDispatch, dispatchInfo.auditKey);
    if (response.status === 200) {
        yield put(DispatchActions.setMessage(`Entry deleted.`))
        yield put(DispatchActions.fetchDispatchers());
    }
}