import React, {  } from 'react';

import AuditorTableUpdateRow from './AuditorTableUpdateRow';

export const AuditorList = (props) => {

    let { auditorList } = props;

    return (
        <>

            {auditorList && auditorList.length > 0 &&
                <div className="Table-container Map-table">
                    <table className="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th className="cell-left">
                                    <abbr> First Name</abbr>
                                </th>
                                <th className="cell-left">
                                    <abbr>  Last Name</abbr>
                                </th>
                                <th className="cell-left">
                                    <abbr>Email</abbr>
                                </th>
                                <th className="cell-left">
                                    <abbr> Phone</abbr>
                                </th>
                                <th></th>
                            <th></th>

                            </tr>
                        </thead>
                        <tbody>
                            {auditorList.map((auditor) =>
                                <React.Fragment key={auditor.auditorKey}>
                                    <AuditorTableUpdateRow auditor={auditor} deleteAuditor={props.deleteAuditor} update={props.updateAuditor}/>
                                </React.Fragment>
                            )}
                        </tbody>
                    </table>
                </div>
            }

        </>
    );
}