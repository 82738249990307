export const Translations = { de: 
   { header_title: 'Securitas Audit Tool',
     all_audits: 'ALLE AUDITS',
     reporting: 'Report',
     drilldown: 'aufreißen',
     dispatch: 'Versand',
     edit_questions: 'Fragen',
     logout: 'Ausloggen',
     global: 'Global',
     filter: 'Filter',
     add_location: 'Add Location',
     add_auditor: 'Add Auditor' },
  en: 
   { header_title: 'Securitas Audit Tool',
     all_audits: 'ALL AUDITS',
     reporting: 'Reporting',
     drilldown: 'Drilldown',
     dispatch: 'Dispatch',
     edit_questions: 'Edit questions',
     logout: 'Logout',
     global: 'Global',
     filter: 'Filter',
     add_location: 'Add Location',
     add_auditor: 'Add Auditor' } }