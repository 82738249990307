import React, { useState, useEffect } from 'react';

import { DispatchersList } from './DispatchersList';

export const Dispatch = (props) => {
    let { data } = props;
    let [instanceType, setInstanceType] = useState(null);
    let [auditor, setAuditor] = useState(null);
    let [location, setLocation] = useState(null);

    useEffect(() => {
        if (data && data.instances && data.instances.length > 0)
            setInstanceType(data.instances[0].instanceKey);

        if (data && data.auditors && data.auditors.length > 0)
            setAuditor(data.auditors[0].auditorKey);

        if (data && data.locations && data.locations.length > 0)
            setLocation(data.locations[0].key);

    }, [data]);

    const handleAuditorChange = event => {
        setAuditor(event.target.value);
    }

    const handleInstanceChange = event => {
        setInstanceType(event.target.value);
    }

    const handleLocationsChange = event => {
        setLocation(event.target.value);
    }

    return (
        <>
                    {props.message &&
                       <div className="notification is-success">{props.message}</div>
                    }            {data &&
                <div>
                    <table className="table admin-table is-fullwidth ">
                        <tbody>

                            {data.instances && data.instances.length > 0 &&
                                <>
                                    <tr><td className="is-label">
                                        <label>Audit</label>
                                    </td><td>

                                            <div className="select"><select onChange={handleInstanceChange} >
                                                {data.instances.map((instance) =>
                                                    <option key={instance.instanceKey} value={instance.instanceKey}>
                                                        {instance.title}
                                                    </option>
                                                )}
                                            </select>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            }
                            {data.auditors && data.auditors.length > 0 &&
                                <>
                                    <tr><td className="is-label">

                                        <label>Auditors</label></td><td>
                                            <div className="select"> <select onChange={handleAuditorChange}>
                                                {data.auditors.map((auditor) =>
                                                    <option key={auditor.auditorKey} value={auditor.auditorKey}>
                                                        {auditor.firstName} {auditor.lastName}, email: {auditor.email}
                                                    </option>
                                                )}
                                            </select>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            }
                            {data.locations && data.locations.length > 0 &&
                                <>
                                    <tr><td className="is-label">

                                        <label>Locations</label></td><td>
                                            <div className="select"><select onChange={handleLocationsChange}>
                                                {data.locations.map((location) =>
                                                    <option key={location.key} value={location.key}>
                                                        {location.name}, client: {location.client}
                                                    </option>
                                                )}
                                            </select>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            }
                     
                        </tbody>
                    </table>
                <button className="button add-btn" onClick={_ => {
                    props.onClick({ instanceType, auditor, location });
                }}
                >
                    Dispatch
                            </button>
                    {data.dispatchers && data.dispatchers.length > 0 &&
                        <DispatchersList dispatchers={data.dispatchers} onDelete={props.onDelete}/>
                    }
                </div>
            }
        </>
    );
}