import React from 'react';
import { connect } from 'react-redux';

import TextMeter from '../components/widgets/TextMeter';

const ReportCategories = (props) => {
    const { categories } = props.summary;
    const catCount = categories.length;
    const categoryBoxes = categories.map(category => {
        return (
            < article className = {
                'tile box widget is-score tile-' + (catCount > 5 ? ' alt-layout ' : catCount)
            }
            key = {
                category.categoryKey
            } >
                <TextMeter label={category.categoryName} value={Math.round(category.score)} />
            </article>
        );
    });

    return (
        <div>
            <div className={'widget-row row-' + (catCount > 5 ? ' widget-row-alt' : catCount) }>
                {categoryBoxes}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        summary: state.dashboard.summary,
    }
}

export default connect(mapStateToProps)(ReportCategories);