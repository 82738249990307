import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoginActions from '../redux/LoginRedux';

import { Field } from '../components/FormElements';
import { Notification } from '../components/Elements';

import { version, logoUrl } from '../resources/config';
import { catchServerErrors } from '../resources/utils';

import User from '../services/user';
import Dashboard from './Dashboard';
import LoginService from '../services/LoginService';

const appStorage = window.localStorage;

const loginErrors = {
    'passworderror': {
        message: 'Password or username is incorrect',
        class: 'is-warning',
    },
    'passwordmissing': {
        message: 'Password or username is missing',
        class: 'is-warning',
    },
    'resetpassword': {
        message: 'Please reset your password',
        class: 'is-info',
    },
    'passwordupdated': {
        message: 'Password is updated, please login with new password',
        class: 'is-success',
    },
    'servererror': {
        message: 'Server error',
        class: 'is-danger',
    },
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            newPassword: '',
            errorMsg: '',
            serverError: '',
            loginOk: false,
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.token !== this.props.token) {
            this.setState({ loginOk: true });
        }
    }

    isLoginOk(message) {
        if (['passworderror', 'resetpassword', 'passwordupdated'].indexOf(message) !== -1) {
            console.log("message", message)
            this.setState({ errorMsg: message });
            return false;
        }
        return true;
    }

    validateLogin() {
        if (this.state.username === '' || this.state.password === '') {
            this.setState({ errorMsg: 'passwordmissing' });
            return false;
        }
        return true;
    }

    login() {
        if (this.state.errorMsg === 'resetpassword') {
            this.resetPassword();
            return false;
        }
        if (!this.validateLogin()) {
            return false;
        }
        this.setState({ isLoading: true });
        User.login(this.state.username, this.state.password).then((res) => {
            if (this.isLoginOk(res.data.message) && res.data.token) {
                sessionStorage.setItem('token', res.data.token);
                appStorage.setItem('userinfo', JSON.stringify(res.data.user));
                this.props.setUserInfo(res.data.user);
                this.setState({ loginOk: true });
            }
            this.setState({ isLoading: false });
        }).catch((e) => {
            this.setState({ isLoading: false });
            this.setState(catchServerErrors(e));
        });
    }

    loginWithAD = () => {
        LoginService.login().then(login => {
            const name = login.name.split(' ');
            this.props.setUserInfo({
                email: login.preferredName, 
                firstName: name[0],
                lastName: name[1], 
                username: login.preferredName,
            });
            this.props.setToken(login.rawIdToken);
        });
    }

    resetPassword() {
        User.resetPassword(this.state.username, this.state.password, this.state.newPassword).then((res) => {
            console.log(res);
            if (this.isLoginOk(res.data.message)) {
                this.setState({ errorMsg: res.data.message });
            }
        }).catch((e) => {
            this.setState(catchServerErrors(e));
        });
    }

    resetForm() {
        this.setState({
            username: '',
            password: '',
            newPassword: '',
            errorMsg: '',
            loginOk: false,
            serverError: '',
        });
    }

    handleInput({ target: { name, value } }) {
        this.setState({
            [name]: value
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (['password', 'newPassword', 'username'].indexOf(event.target.name) !== -1) {
                this.login();
            }
        }
    }

    render() {
        if (this.state.loginOk) {
            return <Dashboard {...this.props} />
        }

        return (
            <div className="container login">
                <div className="login-modal">
                    <div className="login-logo">
                        <img className="securitas-logo" src={logoUrl} width="140" height="78" alt="Securitas logo" />
                    </div>
                    <div>
                        {
                            this.state.errorMsg &&
                            <Notification variant={loginErrors[this.state.errorMsg].class}>
                                {
                                    loginErrors[this.state.errorMsg].message + (this.state.serverError ? ' - ' + this.state.serverError : '')
                                }
                            </Notification>
                        }
                    </div>
                    <Field label="Username" name="username" onKeyPress={(event) => this.handleKeyPress(event)} value={this.state.username} onChange={(event) => this.handleInput(event)} type="text" />
                    <Field label="Password" name="password" onKeyPress={(event) => this.handleKeyPress(event)} value={this.state.password} onChange={(event) => this.handleInput(event)} type="password" />
                    {
                        this.state.errorMsg === 'resetpassword' &&
                        <Field label="New Password" name="newPassword" onKeyPress={(event) => this.handleKeyPress(event)} value={this.state.newPassword} onChange={(event) => this.handleInput(event)} type="password" />
                    }
                    <button className={"button is-primary " + (this.state.isLoading ? 'is-loading' : '')} onClick={() => this.login()}>Client login</button><br />
                    <div className="separator"><span>or</span></div>
                    <button name="securitas-login" className={"button is-secondary "} onClick={() => this.loginWithAD()}>
                        Securitas Login
                    </button>
                    <br />

                    <p className="information-secondary">Securitas Audit Tool <br />Version {version.versionNumber} ({version.lastUpdate})</p>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        token: state.login.token
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (name, email, authMethod) => dispatch(LoginActions.login(name, email, authMethod)),
        setUserInfo: (userInfo) => dispatch(LoginActions.setUserInfo(userInfo)),
        setToken: (token) => dispatch(LoginActions.setToken(token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)