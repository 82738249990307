import React from 'react';
import AuditDetail from './AuditDetail';

const DispatchAuditList = ({audits}) => {
    return (
        <div className="dispatcher-container">
            {
                Object.keys(audits).map((line) => {
                    console.log(audits[line]);
                    return <AuditDetail key={audits[line].key} audit={audits[line]} />
                })
            }
        </div>
    );
}

export default DispatchAuditList;