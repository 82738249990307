import React from 'react';

const Translate = (props) => {

    return (
        <>
            {props.children}
        </>
    )
}

export default Translate