import { put, call } from 'redux-saga/effects';

import API from '../services/Api';
import LocationActions from '../redux/LocationRedux';

const appStorage = window.localStorage;

export function* createLocationSaga({ locationName, latitude, longitude, placeName, city, country, postcode, client }) {
    const response = yield call(API.create().addLocation, locationName,
        latitude, longitude, placeName, city, country, postcode, client);
    if (response.status === 201) {
        yield put(LocationActions.setMessage(`${locationName} has been created.`))
        yield put(LocationActions.fetchLocations());
    }
}

export function* fetchLocationsSaga() {
    let userInfo = JSON.parse(appStorage.getItem('userinfo'));
    let response = null;
    if (userInfo.client === undefined || userInfo.client === 'undefined')
        response = yield call(API.create().fetchAllLocations);
    else
        response = yield call(API.create().fetchLocationsByClient, userInfo.client);

    const clientResponse = yield call(API.create().getClients);

    yield put(LocationActions.setClients(clientResponse.data));
    if (response.status === 200) {
        if (clientResponse.status === 200) {
            yield put(LocationActions.setLocations(response.data));
        }
    }
}

export function* deleteLocationSaga({ location }) {
    const response = yield call(API.create().deleteLocation, location.key);
    if (response.status === 200)
        yield put(LocationActions.setMessage(`Location deleted!`));
    else
        yield put(LocationActions.deleteError('Error while deleting.'));
    yield put(LocationActions.fetchLocations());
}

export function* updateLocationSaga({ locationInfo }) {
    const response = yield call(API.create().updateLocation, locationInfo.locationKey, locationInfo.name, locationInfo.address,
        locationInfo.city, locationInfo.postcode, locationInfo.country, locationInfo.latitude, locationInfo.longitude);
    if (response.status === 200)
        yield put(LocationActions.updateSuccess('Updated successfully.'));
    else
        yield put(LocationActions.updateError('Error while updating.'));
    yield put(LocationActions.fetchLocations());
}

