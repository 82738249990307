import React from 'react';
import { connect } from 'react-redux';

import Component from '../../components/widgets/auditor';

import AuditorActions from '../../redux/AuditorRedux';

class AuditorForm extends React.Component {
    componentDidMount() {
        this.props.fetchAuditors();
    }

    render() {
        return (
            <Component {...this.props} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        message: state.auditor.message,
        auditorList: state.auditor.auditorList,
        updateMessage: state.auditor.updateMessage,
        updateErrorMessage: state.auditor.updateErrorMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addAuditor: (auditorInfo) => dispatch(AuditorActions.addAuditor(auditorInfo)),
        fetchAuditors: _ => dispatch(AuditorActions.fetchAuditors()),
        deleteAuditor: (auditor) => dispatch(AuditorActions.deleteAuditor(auditor)),
        updateAuditor: (auditorInfo) => dispatch(AuditorActions.updateAuditor(auditorInfo)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditorForm)