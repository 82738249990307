import React from 'react';
import Thumbnail from './Thumbnail';

const AuditQuestion = (props) => {
    const { questionNumber, heading, value, comment, parent } = props.question;
    const formatedHeading = heading.replace(/\*/g, ''); // Temporary remove markup ref jira SAT-237
    let thumbs = null;
    if (props.images) {
        thumbs = props.images.map(image => {
            return <Thumbnail key={image.key} image={image} />
        });
    }

    return (
        <div className="question-entry">
            <p className={"question " + (parent ? 'question-followup' : false)}>{questionNumber} - {formatedHeading}</p>
            {value !== -1 && <p className={"question-answer " + (value === 1 ? 'yes' : 'no')}>{value === 1 ? 'Yes' : 'No'}</p>}
            <div className="question-note">{comment ? <span>Note:</span> : false}
                <div>{comment}</div>
            </div>
            <div className="thumbnail-row">
                {thumbs}
            </div>
        </div>
    );
}

export default AuditQuestion;