import React from 'react';

import Progress from 'react-bulma-components/lib/components/progress';

const ProgressMeter = ({ value, max, heading, label }) => {
    return (
        <>
              <p className="heading meta-label">{heading}</p>
            <Progress max={max} value={value} color="success" />

            < p className= "secondary-text" >
            {value} / {max} {label}</p>
        </>

    );
}

ProgressMeter.defaultProps = {
    label: 'Completed',
    value: 0,
    max: 100
};

export default ProgressMeter;