import moment from 'moment';
import {dateFormat} from '../resources/config';

export function catchServerErrors(e) {
    console.log(e.response);
    if (!e.response) {
        return {
            errorMsg: 'servererror',
            serverError: 'Network error, is the server running?', 
        };    
    } else {
        return {
            errorMsg: 'servererror',
            serverError: e.response.status + ': ' + e.response.statusText, 
        };    
    }
}

export function formatedDate(yourDate) {
    return moment(yourDate).format(dateFormat);
}