import { put, call } from 'redux-saga/effects';

import LoginActions from '../redux/LoginRedux';
import API from '../services/Api';


export function* login({ name, email, authMethod }) {
    let response = yield call(API.create().login, name, email, authMethod);

    if (response.status === 200) {
        let { data } = response
        yield put(LoginActions.setToken(data.token))
    }
}

