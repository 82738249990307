import React, { useState, useEffect } from 'react';

import { Field } from '../../FormElements';

const LocationUpdateRow = ({ location, update, deleteLocation, toggle, }) => {

    let [name, setName] = useState('');

    useEffect(() => {
        setName(location.name);
    }, [location]);

    const handleNameChange = event => {
        setName(event.target.value);
    }

    return (
        <tr>
            <td className="cell-left">
                <Field name="name" value={name} 
                    onChange={handleNameChange}
                    style={{ width: '10px' }} />
            </td>
            <td className="cell-small">
                <span>{location.position.latitude}</span>
            </td>
            <td className="cell-small">
                <span>{location.position.longitude}</span>
            </td>
            <td>
                <span>{location.address}</span>
            </td>
            <td>
                <span>{location.postcode}</span>
            </td>
            <td>
                <span>{location.city}</span>
            </td>
            <td>
                <span>{location.country}</span>
            </td>
            <td>
                <span>{location.client}</span>
            </td>
            <td className="cell-center">
                <button
                    className="button update-btn"
                    onClick={
                        _ => {
                            const locationInfo = {
                                'locationKey': location.key,
                                'name': name,
                                'address': location.address,
                                'city': location.city,
                                'country': location.country,
                                'postcode': location.postcode,
                                'latitude': location.position.latitude,
                                'longitude': location.position.longitude,
                            }
                            toggle();
                            update(locationInfo);
                        
                        }
                    }
                >
                    Update
                </button>
            </td>
            <td className="cell-center">
                <button
                    className="button delete-btn"
                    onClick={_ => {deleteLocation(location);
                        toggle();
                    }
                    }
                >
                    Delete
                </button>
            </td>
        </tr>
    );
}

export default LocationUpdateRow;