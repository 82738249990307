import React from 'react';

import AuditSelector from './AuditSelector';

const AuditList = ({audits}) => {

    return (
        <div className="card primary-card">
        <div className="breadcrumbn"><p>Drilldown summary / <b>Audit Types</b></p></div>
            {
                Object.keys(audits).map((line) => {
                    return <AuditSelector key={line} title={audits[line].title} description={audits[line].description} />    
                })
            }
        </div>
    );
}

export default AuditList;