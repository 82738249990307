import React from 'react';
import { connect } from 'react-redux';
import { limits } from '../resources/config';
import { pluralize } from '../services/language';

const CategoryBreakdown = (props) => {
    const { categories, categoriesLocation, status, locations } = props.summary;
    if (!locations) {
        return false;
    }
    let totalPassed = 0;
    let totalFair = 0;
    let totalFailed = 0;
    
    // Count number of passed, failed and location with a fair total score
    locations.forEach(location => {
        if (location.score < limits.failed) {
            totalFailed++;
        } else if (location.score > limits.passed) {
            totalPassed++;
        } else {
            totalFair++;
        }
    });

    const categorySummary = {};
    categories.forEach(c => {
        categorySummary[c.categoryKey] = {categoryName: c.categoryName, passed: 0, fair: 0, failed: 0};
    });

    categoriesLocation.forEach(c => {
        if (c.score < limits.failed) {
            categorySummary[c.categoryKey].failed++;
        } else if (c.score > limits.passed) {
            categorySummary[c.categoryKey].passed++;
        } else {
            categorySummary[c.categoryKey].fair++
        }
    });   
    
    const categoryList = categories.map(c => {
        const passedPercent = Math.round(parseFloat((categorySummary[c.categoryKey].passed/(status.executedcount))*100));
        const fairPercent = Math.round(parseFloat((categorySummary[c.categoryKey].fair/(status.executedcount))*100));
        const failedPercent = Math.round(parseFloat((categorySummary[c.categoryKey].failed/(status.executedcount))*100));
        return (
            <div className="breakdown-row" key={c.categoryKey}>
                <div className="breakdown-cell first ">
                    <p className="subcat">Category</p>
                    <p>{c.categoryName}</p>
                </div>
                <div className="breakdown-cell">
                    <span className="score">{categorySummary[c.categoryKey].passed}</span>
                    <span className="score-perc">({passedPercent}%)</span>
                </div>
                <div className="breakdown-cell">
                    <span className="score">{categorySummary[c.categoryKey].fair}</span>
                    <span className="score-perc">({fairPercent}%)</span>
                </div>
                <div className="breakdown-cell">
                    <span className="score">{categorySummary[c.categoryKey].failed}</span>
                    <span className="score-perc">({failedPercent}%)</span>
                </div>
            </div>
        );
    });
    
    const totalPassPercent = Math.round(parseFloat((totalPassed/(status.executedcount))*100));
    const totalFairPercent = Math.round(parseFloat((totalFair/(status.executedcount))*100));
    const totalFailPercent = Math.round(parseFloat((totalFailed/(status.executedcount))*100));

    return (
        <div className="columns breakdown-container">
            <div className="column is-full">
                <article className="tile is-child box widget is-breakdown">
                    <h4 className="widget_title">Result breakdown (Out of {status.executedcount} {pluralize(status.executedcount, 'location')})</h4>

                    <div className="breakdown-row first-row">
                        <div className="breakdown-cell first empty"></div>
                        <div className="breakdown-cell heading">
                            Passed
                        </div>
                        <div className="breakdown-cell heading">
                            Fair
                        </div>
                        <div className="breakdown-cell heading">
                            Failed
                        </div>
                    </div>

                    <div className="breakdown-row">
                        <div className="breakdown-cell first">
                            <p className="subcat"></p>
                            Total Score
                        </div>
                        <div className="breakdown-cell">
                            <span className="score">{totalPassed}</span>
                            <span className="score-perc">({totalPassPercent}%)</span>
                        </div>
                        <div className="breakdown-cell">
                            <span className="score">{totalFair}</span>
                            <span className="score-perc">({totalFairPercent}%)</span>
                        </div>
                        <div className="breakdown-cell">
                            <span className="score">{totalFailed}</span>
                            <span className="score-perc">({totalFailPercent}%)</span>
                        </div>
                    </div>

                    {categoryList}

                </article>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        summary: state.dashboard.summary,
    }
}

export default connect(mapStateToProps)(CategoryBreakdown);