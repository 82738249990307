import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getData: ['instance'],
    setData: ['data'],
    setInstance: ['instance'],
    getSummary: ['instance'],
    setSummary: ['summary'],
    setAudit: ['audit'],
    getAudit: ['audit'],
    setAuditData: ['auditData'],
    getAuditData: ['auditData'],
    setActiveState: ['activeTab'],
    setChange : ['change'],
    getInstances: [],
    setInstances: ['instances'],
})

export const DashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: {},
    summary: {
        countries: [],
        status: {},
        categories: [],
        categoriesLocation: [],
    },
    instance: null,
    audit: '',
    auditData: {},
    activeTab: 'summary',
    change: false,
    instances: [],
})

/* ------------- Reducers ------------- */

export const getData = (state: Object) => {
    return state
}

export const setData = (state: Object, { data }: Object) => {
    return state.merge({ data })
}

export const setInstance = (state: Object, { instance }: Object) => {
    return state.merge({ instance });
}

export const setAudit = (state: Object, { audit }: Object) => {
    return state.merge({ audit });
}

export const setActiveState = (state: Object, { activeTab }: Object) => {
    return state.merge({ activeTab });
}

export const setChange = (state: Object, { change }: Object) => {
    return state.merge({ change });
}

export const getAudit = (state: Object) => {
    return state;
}

export const setAuditData = (state: Object, { auditData }: Object) => {
    return state.merge({ auditData });
}

export const getAuditData = (state: Object) => {
    return state;
}

export const getSummary = (state: Object) => {
    return state;
}

export const setSummary = (state: Object, { summary }: Object) => {
    return state.merge({ summary });
}

export const getInstances = (state: Object) => {
    return state;
}

export const setInstances = (state: Object, { instances }: Object) => {
    return state.merge({ instances });
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_DATA]: getData,
    [Types.SET_DATA]: setData,
    [Types.SET_INSTANCE]: setInstance,
    [Types.GET_INSTANCES]: getInstances,
    [Types.SET_INSTANCES]: setInstances,
    [Types.GET_SUMMARY]: getSummary,
    [Types.SET_SUMMARY]: setSummary,
    [Types.SET_AUDIT]: setAudit,
    [Types.GET_AUDIT]: getAudit,
    [Types.SET_AUDIT_DATA]: setAuditData,
    [Types.GET_AUDIT_DATA]: getAuditData,
    [Types.SET_ACTIVE_STATE]: setActiveState,
    [Types.SET_CHANGE]: setChange,
})
