import React, { Component } from 'react';

import { connect } from 'react-redux';
import DashboardActions from '../redux/DashboardRedux';
import PercentMeter from '../components/widgets/PercentMeter';
import ProgressMeter from '../components/widgets/ProgressMeter';
import { pluralize } from '../services/language';
import Modal from '../components/Modal';
import {
    ReactComponent as IconExport
} from 'design/svg/export.svg';
import moment from 'moment';

class ReportSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgressModal: false,
            showLocationModal: false,
            locationStatus: '',
        };
    }

    toggleProgressModal() {
        this.setState({
            showProgressModal: this.state.showProgressModal ? false : true
        });
    }

    auditChange(auditKey) {
        this.props.setAudit(auditKey);
        this.props.setChange(false);
        this.setState({
            showProgressModal: false,
            showLocationModal: false,
        });
    };

    toggleLocationModal(countryCode) {
        this.setState({
            locationStatus: this.getLocationStatusCountry(countryCode),
            showLocationModal: this.state.showLocationModal ? false : true
        });
    }

    getLocationStatusCountry(countryCode) {
        return this.props.summary.instanceLocations.map((l, key) => {
            if (l.countryCode === countryCode) {
                return (
                    <div className="data-row" key={key} onClick={() => this.auditChange(l.auditKey)}>
                        <span className="int-link card-data">{l.locationName} </span>
                        <span className="status">{l.executed ? "Completed" : "In progress"}</span>
                    </div>
                );
            }
            return false;
        });
    }

    render() {
        const { totalScore, countries, status, instance, instanceLocations } = this.props.summary;
        const { showProgressModal, showLocationModal } = this.state;
        if (!totalScore) {
            return false;
        }

        const countriesList = countries.map((country, key) =>
            <li key={key}>
                <span className="int-link card-data" onClick={() => this.toggleLocationModal(country.countryCode)}>
                    {country.countryName} ({country.locationCount})
                </span>
            </li>
        );

        

        const locationListTotal = instanceLocations.map((location, key) => {
            return (
                <div className="data-row" key={key} onClick={() => this.auditChange(location.auditKey)}>
                    <span className="int-link card-data">{location.locationName}</span>
                    <span className="status"> {location.executed ? "Completed" : "In progress"}</span>
                </div>
            );
        });

        let search = (key, inputArray) => {
            for (let i = 0; i < inputArray.length; i++) {
                if (inputArray[i].locationName === key) {
                    return inputArray[i];
                }
            }
        }
        const lastLocationKey = search(status.lastlocation, instanceLocations).auditKey;
    
        return (
            <>
                <Modal show={showProgressModal} toggle={() => this.toggleProgressModal()} message={locationListTotal} />
                <Modal show={showLocationModal} toggle={() => this.toggleLocationModal()} message={this.state.locationStatus} />

                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>Report Summary</li>
                        <li className="is-active"><span className="currentLocation" aria-current="page">Audit Instance</span></li>
                    </ul>
                </nav>

                <div className="columns report-title-container">
                    <div className="column is-two-thirds">
                        <h2 className="title is-2">{instance.title}</h2>
                        <h4 className="subtitle is-4">{instance.description}</h4>
                    </div>
                    <div className="column">
                        <div className="last-col export-col">
                            <button className="button btn-export is-primary" onClick={() => window.print()}>
                                <IconExport width={24} height={24} />
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="columns card-meta">
                    <div className="column is-two-thirds">
                        <div className="tile is-ancestor">
                            <div className="tile is-vertical is-parent is-4 ">
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Date</p>
                                        <p className="card-data">{moment(status.firstdispatch).format('D MMMM YYYY')} </p>
                                    </div>
                                </div>
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Last Submission</p>
                                        <p className="card-data int-link" onClick={() => this.auditChange(lastLocationKey)}>{status.lastlocation} </p>
                                        <p className="secondary-text card-meta-data ">{moment(status.lastsubmission).format('D MMMM YYYY, h:mma')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tile is-vertical is-parent is-4 ">
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Client</p>
                                        <p className="card-data client-name">{instance.clientName}</p>
                                    </div>
                                </div>
                                <div className="tile progress-meter" onClick={() => this.toggleProgressModal()}>
                                    <div>
                                        <ProgressMeter value={status.executedcount} max={status.auditcount} heading="Status" />
                                    </div>
                                </div>
                            </div>
                            <div className="column is-parent">
                                <div className="tile">
                                    <div className="location-container">
                                        <p className="heading meta-label">{pluralize(countriesList.length, 'location')}</p>
                                        <ul>
                                            {countriesList}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="">
                            <div className="item html">
                                <PercentMeter value={Math.round(totalScore)} />
                            </div>
                        </div>
                    </div>
                </div>
                                <hr />

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        instance: state.dashboard.instance,
        summary: state.dashboard.summary,
        data: state.dashboard.data,
        activeTab: state.dashboard.activeTab,
        change: state.dashboard.change,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAudit: (auditKey) => dispatch(DashboardActions.setAudit(auditKey)),
        setActiveState: (activeTab) => dispatch(DashboardActions.setActiveState(activeTab)),
        setChange: (change) => dispatch(DashboardActions.setChange(change))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSummary);