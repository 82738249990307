import React from 'react';
import { ReactComponent as IconTic } from 'design/svg/tick.svg';
import { ReactComponent as IconCross } from 'design/svg/cross.svg';
import {FAIL_SCORE} from 'resources/config'

const TextMeter = ({ value, label, average }) => {
    let diff = Math.abs(average - value);
    return (

            <div>
                <div className={value > FAIL_SCORE ? 'top-left score-passorfail pass' : 'top-left score-passorfail fail'}>
                    {value > FAIL_SCORE ? <IconTic width={14} height={14} /> : <IconCross width={14} height={14} />}
                {value > FAIL_SCORE ? "pass" : "fail"}</div>
                <div className="top-right score-compared">  {value > average ? Math.round(diff) + "% > Average" : Math.round(diff) + "% < Average" } </div>

                    <p className="score-perc {color}">{value}<span>%</span></p>
                    <h4 className="score-cat">{label}</h4>
                </div>
        
    );
}
        
export default TextMeter;