import React from 'react';
import { connect } from 'react-redux';

import Component from '../../components/dispatch';

import DispatchActions from '../../redux/DispatchRedux';

class Dispatch extends React.Component {
    componentDidMount() {
        this.props.fetchDispatchers();
    }

    render() {
        return (
            <Component {...this.props} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.dispatch.data,
        message: state.dispatch.message,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDispatchers: _ => dispatch(DispatchActions.fetchDispatchers()),
        onClick: (dispatchInfo) => dispatch(DispatchActions.addDispatch(dispatchInfo)),
        onDelete: (dispatchInfo) => dispatch(DispatchActions.deleteDispatch(dispatchInfo)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dispatch)