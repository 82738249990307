import React, { Component } from 'react';
import { connect } from 'react-redux';

import DashboardActions from '../redux/DashboardRedux';

import PercentMeter from './widgets/PercentMeter';
import AuditQuestions from './AuditQuestion';
import TextMeter from './widgets/TextMeter';

import Api from '../services/Api';
import {
    ReactComponent as IconExport
} from 'design/svg/export.svg';
import { formatedDate } from '../resources/utils';

const filterTabs = [
    {
        title: 'All',
        name: 'tab-all',
    },
    {
        title: 'Failed',
        name: 'tab-failed',
    },
    {
        title: 'Passed',
        name: 'tab-passed',
    },
];

class AuditDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'tab-all',
            categoryFilter: '',
            passedFilter: null,
            images: {},
        };
    }

    changeTab(event) {
        this.setState({ selectedTab: event.currentTarget.id });
    }

    changeCategoryFilter(event) {
        this.setState({ categoryFilter: event.target.value });
    }

    componentDidMount() {
        this.setState({ images: { ...this.props.auditData.images } }, () => {
            this.getImages();
        });
    }

    getImages() {
        const api = Api.create();
        let newState = { ...this.state.images };
        for (let question in newState) {
            let imageList = [...newState[question]];
            newState[question].forEach((image, index) => {
                let img = { ...image };
                api.getThumb(image.thumbName).then(response => {
                    if (response.status === 200) {
                        img.localRef = window.URL.createObjectURL(new Blob([response.data]));
                        imageList[index] = img;
                        newState[question] = imageList;
                        this.setState({ images: newState });
                    }
                });
            });
        }
    }

    render() {
        const { imageCount, questionAnswered, questionTotal, questionPassed, questionFailed, location, audit, categories } = this.props.auditData;
        const { images } = this.state;
        const { firstName, lastName } = this.props.auditData.audit;
        const average = this.props.summary.categories;
        const { selectedTab, categoryFilter } = this.state;
        const submissions = <> <p className="card-data"> {questionAnswered} / {questionTotal}</p><p className="secondary-text card-meta-data"> {imageCount} images uploaded</p></>;


        var averageCat = [];

        averageCat = average.map(c => {

            return (
                { key: c.categoryKey, score: c.score }
            );
        })

        const tabs =
            <ul>
                {filterTabs.map((tab, i) => {
                    return <li key={i} className={'tab' + (selectedTab === tab.name ? ' active' : '') + (tab.name === 'tab-failed' && (questionFailed === 0) ? ' hide' : '')} id={tab.name} onClick={(event) => this.changeTab(event)}>{tab.title} ({tab.name === 'tab-passed' ? questionPassed : ''}{tab.name === 'tab-all' ? questionTotal : ''}{tab.name === 'tab-failed' ? questionFailed : ''})</li>
                })}
            </ul>

                const catCount = categories.length;

        const categoryBoxes =
            <div className={'widget-row row-' + (catCount > 5 ? ' widget-row-alt' : catCount)}>
                {categories.map((c, i) => {
                    let currentKey = averageCat.findIndex(x => x.key === c.key);
                    return (
                        <article className={'tile box widget is-score audit-detail tile-' + (catCount > 5 ? ' alt-layout ' : catCount)} key={i}>
                            <TextMeter value={Math.round(c.score)} label={c.title} average={averageCat[currentKey]['score']} />
                        </article>
                    );
                })}
            </div>;

        const categoryOptions = categories.map((c, i) => {
            return <option key={i} value={c.key}>{c.title}</option>
        });

        const questions =
            <div>
            
                {categories.map(c => {
                    if (String(categoryFilter) === String(c.key) || !categoryFilter) {
                        return (
                            <div key={c.key}>
                                {(selectedTab !== 'tab-failed' || c.score !== 100) && <h2 className="question-category">{c.title} - {c.score}%</h2> }
                                {c.questions.map(q => {
                                    if (selectedTab === 'tab-all' ||
                                        (selectedTab === 'tab-failed' && !q.passed) ||
                                        (selectedTab === 'tab-passed' && q.passed)) {
                                        return <AuditQuestions
                                            key={q.key}
                                            question={q}
                                            images={images[q.key] ? images[q.key] : null}
                                        />
                                    }
                                    return false;
                                })}
                            </div>
                        );
                    }
                    return false;
                })}
            </div>

        // Only show auditor name if it is supplied by the API
        let auditor;
        if (firstName || lastName) {
            auditor = 
            <div>
                <p className="heading meta-label">Auditor</p>
                <p className="card-data">{firstName} {lastName}</p>
            </div>
        }

        return (
            <div>
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>Report</li>
                        <li className="is-active"><span className="currentLocation" aria-current="page">Audit Location</span></li>
                    </ul>
                </nav>
                <div className="columns report-title-container">
                    <div className="column is-two-thirds">
                        <h2 className="title is-2"> {location.name} - {audit.title}</h2>
                        <h4 className="subtitle is-4">{audit.description}</h4>
                    </div>
                    <div className="column">
                        <div className="last-col export-col">
                            <button className="button btn-export is-primary" onClick={() => window.print()}>
                                <IconExport width={24} height={24} />
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                <hr />

                {/* Basic info and Export button end here */}
                <h2>{categoryFilter}</h2>
                <div className="columns card-meta">
                    <div className="column is-two-thirds">
                        <div className="tile is-ancestor">
                            <div className="tile is-vertical is-parent is-4 ">
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Completion Date</p>
                                        <p className="card-data">{audit.executed ? formatedDate(audit.executed) : '-'}</p>
                                    </div>
                                </div>
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Submission</p>
                                        {submissions}
                                    </div>
                                </div>
                            </div>


                            <div className="tile is-vertical is-parent is-4 ">
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Client</p>
                                        <p className="card-data client-name">{audit.clientName}</p>
                                    </div>
                                </div>
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Status</p>
                                        <p className="card-data">{audit.executed ? 'Completed' : 'In progress'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tile is-vertical is-parent is-4 ">
                                <div className="tile">
                                    <div>
                                        <p className="heading meta-label">Location</p>
                                        <p className="card-data">{location.name}</p>
                                    </div>
                                </div>
                                <div className="tile">
                                    {auditor}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Basic info ends here.*/}

                    <div className="column">
                        <div className="">
                            <div className="item html">
                                <PercentMeter value={Math.round(audit.totalScore)} />
                            </div>
                        </div>
                    </div>

                </div>

                {/* Report summary ends here.*/}

                <hr></hr>

                {audit.executed && categoryBoxes}



                <div className="columns audit-breakdown-container">

                    <div className="column is-full">
                        <div className="tabs widget-tabs">
                            {tabs}
                        </div>
                        <article className="tile is-child box widget is-auditBreakdown">
                            <h4 className="widget_title">Audit breakdown </h4>
                            <div className="select">
                                <select name="country" onChange={(event) => this.changeCategoryFilter(event)}>
                                    <option value="">All Categories</option>
                                    {categoryOptions}
                                </select>
                            </div>

                            {questions}

                        </article>
                    </div>
                </div>
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAudit: (audit) => dispatch(DashboardActions.getAudit(audit)),
    }
}

const mapStateToProps = (state) => {
    return {
        audit: state.dashboard.audit,
        auditData: state.dashboard.auditData,
        summary: state.dashboard.summary,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditDetails);