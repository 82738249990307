import React from 'react';

function WithLoading(Component) {
    return function WihLoadingComponent({
        isLoading,
        ...props
    }) {
        if (!isLoading) return ( < Component {
                ...props
            }
            />);
            return ( < div className = "loading-container" > < div className = "loading" > Loading... </div></div> );
        }
    }
    
    
    export default WithLoading;
