import React, { useState } from 'react'
import { connect } from 'react-redux';
import PropType from 'prop-types';

import DashboardActions from '../../redux/DashboardRedux';
import { pluralize } from '../../services/language';

const MapDataTable = ({ data, onCountryRowClick, setAudit, setChange }) => {
    let [locationData, setLocationData] = useState(null);

    let handleCountryRowClick = (countryOrLocation, isCountry) => {
        onCountryRowClick(countryOrLocation, isCountry);
        if (isCountry) {
            setLocationData(countryOrLocation);
        }
    };

    const auditChange = (auditKey) => {
        setAudit(auditKey);
        setChange(false);
        window.scrollTo(0, 0);
    };

    return (
        <div className="Map-table">
            <table className="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th className="cell-left"><abbr title='Country'>Country</abbr></th>
                        <th><abbr title='Total Score'>Total Score</abbr></th>
                        {data && data.categories && data.categories.map((item) => (
                            <th key={item.key}><abbr title={`${item.key}`}>{item.caption}</abbr></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data && data.dataSets && data.dataSets.map((country) => (
                        <React.Fragment key={country.countryCode}>
                            <tr key={country.countryCode} onClick={() => handleCountryRowClick(country, true)}>
                                <td className="cell-left cell-country">{country.countryName}
                                    <span className="secondary-text"> {country.locations.length} {pluralize(country.locations.length, 'location')}</span>
                                </td>
                                <td className="cell-score">{country.data.score}%</td>
                                {country.categories.map((item) => (
                                    <td key={item.categoryKey}>{item.score}%</td>
                                ))}
                            </tr>
                            {/* Show Location data if locationData is not null */}
                            {locationData && locationData.countryName === country.countryName && locationData.locations.map((location, i) => (
                                <tr key={i} onClick={() => auditChange(location.auditKey)} className="tr-location">
                                    <td className="cell-left cell-location" >
                                        <span>
                                            {location.name}
                                            <span className="secondary-text">
                                            {location.city}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="cell-score">
                                        <span>{location.data.score}%</span>
                                    </td>
                                    {location.categories.map((category, i) => (
                                        <td key={i}>
                                            <span>
                                                {category.score}%
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            ))
                            }

                        </React.Fragment>
                    ))}

                </tbody>
            </table>
        </div>
    )
}


MapDataTable.propTypes = {
    data: PropType.object.isRequired,
    onCountryRowClick: PropType.func.isRequired,
    setAudit: PropType.func.isRequired,
    setChange: PropType.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAudit: (auditKey) => dispatch(DashboardActions.setAudit(auditKey)),
        setChange: (change) => dispatch(DashboardActions.setChange(change)),
    }
}

export default connect(null, mapDispatchToProps)(MapDataTable);