import React from 'react';

import { ReactComponent as Iconreporting } from 'design/svg/reporting_1.svg';
import { ReactComponent as Icondispatch } from 'design/svg/dispatch_1.svg';
import { ReactComponent as IcondExpand } from 'design/svg/pointer-outline-left.svg';
import { ReactComponent as IconLocation } from 'design/svg/Location_2.svg';
import { ReactComponent as IconAuditor } from 'design/svg/Add_user.svg';

import t from '../translations'

const SideBar = ({ changeSection, activeSection, sideBarAction, sideBarWidth, widthClass, language }) => {
    return (
        <aside className={'menu side-menu ' + widthClass} style={{ width: sideBarWidth + 'px' }}>
            <ul className="menu-list" >
                < li onClick={
                    () => changeSection('reporting')
                } >
                    <div className={activeSection === 'reporting' ? 'is-active' : ''}>
                        <span className="inline-icon">
                            <Iconreporting width={28} height={28} />
                        </span>
                        <span className="sidemenu-item-text">
                            {t[language].reporting}
                        </span>
                    </div>
                </li>
   <li className="sidebar-btn">
                    <div className="button is-text" onClick={() => sideBarAction()}>
                        <span className="inline-icon">
                            <IcondExpand width={28} height={28} />
                        </span>
                    </div>
                </li>
                     <li onClick={() => changeSection('locationform')}>
                    < div className={
                        activeSection === 'locationform' ? 'is-active' : ''
                    } >
                        <span className="inline-icon">
                            <IconLocation width={28} height={28} />
                        </span>
                        <span className="sidemenu-item-text">
                            {t[language].add_location}
                        </span>
                    </div>
                </li>

                <li onClick={() => changeSection('auditorform')}>
                    < div className={
                        activeSection === 'auditorform' ? 'is-active' : ''
                    } >
                        <span className="inline-icon">
                            <IconAuditor width={28} height={28} />
                        </span>
                        <span className="sidemenu-item-text">
                            {t[language].add_auditor}
                        </span>
                    </div>
                </li>
                <li onClick={() => changeSection('dispatch')}>
                    <div className={activeSection === 'dispatch' ? 'is-active' : ''}>
                        <span className="inline-icon">
                            <Icondispatch width={28} height={28} />
                        </span>
                        <span className="sidemenu-item-text">
                            {t[language].dispatch}
                        </span>
                    </div>
                </li>
            </ul>

        </aside>
    );
}

export default SideBar;