import React from 'react';

import { environment, logoUrl } from '../resources/config';
import t from '../translations';
import Profile from './Profile';
const currentEnv = environment.label === 'production' ? '' : (' (' + environment.label + ')');

const Header = (props) => {
  

    return (
        <>
        <div className="top-header">
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <div className="navbar-item" href="#">
                        <img className="c-site-header__logo site-logo" src={logoUrl} width="111" height="62" alt="Securitas logo" />
                        <h4>{t[props.language].header_title}<strong>{currentEnv}</strong></h4>
                    </div>
                    <div onClick={() => props.toggleBurger()} role="button" className={'navbar-burger burger '  + (props.burgerOpen ? 'is-active' : '')} aria-label="menu" aria-expanded="false" data-target="">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </div>
                </div>
                <div className={'navbar-menu ' + (props.burgerOpen ? 'is-active' : '')}>
                    <div className="navbar-end header-admin">
                       
                        <div className="navbar-item  header-login" onClick={() => props.toggleProfile()}>
                     
                            {props.firstName} {props.lastName}
                      
                        </div>
                    </div>
                </div>
            </nav>
        </div>
                          {props.showProfile &&
  <Profile 
        firstName= {props.firstName} 
        lastName={props.lastName}
        handleLanguageChange={props.handleLanguageChange}
        logOut={() => props.logOut()}
        language = {props.language}
        />
        }
        </>
    );
}

export default Header;