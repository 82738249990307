import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const PercentMeter = ({ value, label }) => {
    let barColour = '#4BCC14';
    if (value <= 79){
        barColour = '#FFCE0D';
    } else if (value <= 39) {
        barColour = '#CC1814';
    }
    const data = {
        datasets: [{
            data: [value, 100 - value],
            backgroundColor: [
                barColour, // green color
                '#f2f2f2', // grey color
            ],
            borderWidth: 0,
            text: '23%'
        }],
        
    };

     const options = {
            hover: {
                mode: null
            },

         tooltips: {
             enabled: false
         },
         cutoutPercentage: 85,

     }
    return (
        <div className="doughnut-item">
            <div className="doughnut-wrapper">
                <Doughnut
                    data={data}
                    options={ options}
                />
                <h2 className="score-perc">{value}<span>%</span></h2>
                <h4>Total score</h4>
            </div>
            <h4 className="score-cat">{label}</h4>

        </div>
    );
}

export default PercentMeter;