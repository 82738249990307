import React from 'react';

import { connect } from 'react-redux';
import L from 'leaflet'

import Component from '../components/widgets/LeafletMap';
import DashboardActions from '../redux/DashboardRedux';

class LeafletMap extends React.Component {
    mapRef = React.createRef();


    state = {
        zoom: 3,
        latitude: null,
        longitude: null,
    };

    componentDidMount() {
        this.props.fetchData(this.props.instance);
    }

    componentDidUpdate(prevProps, prevState, nextProps) {
        let { data } = this.props;
        let { dataSets } = data;
        if (prevProps.data !== this.props.data) {
            if (dataSets && dataSets.length > 0) {
                let [latitude, longitude] = [dataSets[0].locations[0].position.latitude, dataSets[0].locations[0].position.longitude];
                this.setLatLong(latitude, longitude, 3);
            }
        }
    }

    handleOnCountryRowClick = (countryOrLocation, isCountry = false) => {
        if (isCountry) {
            let [latitude, longitude] = [countryOrLocation.locations[0].position.latitude, countryOrLocation.locations[0].position.longitude]
            this.setLatLong(latitude, longitude, 4)
        } else {
            let [latitude, longitude] = [countryOrLocation.position.latitude, countryOrLocation.position.longitude]
            this.setLatLong(latitude, longitude, 11)
        }
    }

    setClusterStyle = (cluster, country) => {
        const count = cluster.getChildCount();
        const score = country.data.score;

        let size = 'small';

        if (score <= 39.9) {
            size = 'small';
        }
        else if (score >= 40 && score < 79.9) {
            size = 'medium';
        }
        else if (score >= 80 && score <= 100) {
            size = 'large';
        }
        const options = {
            cluster: `leaflet-marker-icon leaflet-zoom-animated  marker-cluster marker-cluster-${size}`,
        };

        return L.divIcon({
            html:
                `<div>
                    <span className="markerClusterLabel">${count}</span>
                </div>`,
            className: `${options.cluster}`,
        });
    }

    setLatLong = (latitude, longitude, zoom) => {
        this.setState({
            latitude,
            longitude,
            zoom
        })
    }

    render() {
        let { data } = this.props;
        let { zoom, latitude, longitude } = this.state;
        return (
            <>
                {data && data.dataSets && latitude && longitude &&
                    <Component
                        latitude={latitude}
                        longitude={longitude}
                        zoom={zoom}
                        getClusterStyle={this.setClusterStyle}
                        data={data}
                        handleMarkerMouseOver={this.handleMarkerMouseOver}
                        onCountryRowClick={this.handleOnCountryRowClick}
                        ref={this.mapRef}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.dashboard.data,
        instance: state.dashboard.instance,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (instance) => dispatch(DashboardActions.getData(instance))
    }
}

const LeafletContainer = connect(mapStateToProps, mapDispatchToProps)(LeafletMap)

export default LeafletContainer;